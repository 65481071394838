const RoutesDef = {
    HOME: "/",

    LOGIN: "/login",
    LOGOUT: "/logout",
    CAS_LOGIN: "/cas-login",
    GOOGLE_LOGIN: "/google-login",
    LOGIN_TASK_FORWARDED: "/login-task-forwarded",

    WEBSOCKET: "/websocket",
    RELEASES: "/releases",
    ABOUT: "/about",

    /* Acocunt */
    ACCOUNT_CREATE_ACCOUNT: "/account/create/:userId/:timestamp/:hash",
    ACCOUNT_RECOVER: "/account/recover",
    ACCOUNT_RESET_PASSWORD: "/account/reset-password/:userId/:timestamp/:hash",

    /* Users */
    USERS: "/users",
    USERS_EDIT: "/users/:userId",
    USERS_CREATE: "/users/create",

    /* Terrains */
    TERRAINS: "/terrains",
    TERRAINS_CREATE: "/terrains/create",
    TERRAINS_EDIT: "/terrains/:terrainId",

    /* Layers */
    LAYERS: "/terrain-layers",
    LAYERS_MANAGED_EDIT: "/managedLayers/edit/:layerId",
    LAYERS_EDIT: "/terrain-layers/:layerId",

    /* Models */
    MODELS: "/terrain-model",
    MODELS_EDIT: "/terrain-model/:terrainModelId",

    /* Activities */
    ACTIVITIES: "/activities",
    ACTIVITIES_CREATE: "/activities-create",
    ACTIVITIES_EDIT: "/activities/:activityId",

    /* Sessions */
    SESSIONS: "/sessions",
    SESSIONS_EDIT: "/sessions/:sessionId",
    SESSIONS_RECORDS: "/session-records",
    SESSIONS_RECORDS_EDIT: "/session-records/:sessionId",

    /* Jobs */
    JOBS: "/jobs",
    JOBS_CREATE: "/jobs/create",
    JOBS_EDIT: "/jobs/:jobId",

    /* Admin */
    ADMIN_TEAMS: "/admin/teams",
    ADMIN_MY_PROFILE: "/my/profile",
    ADMIN_MY_TEAMS: "/my/teams",
    ADMIN_MY_TEAMS_DETAILS: "/my/teams/details/:teamId",
    ADMIN_TEAM_CREATE: "/admin/teams/create",
    ADMIN_TEAMS_EDIT: "/admin/teams/edit/:teamId",
    ADMIN_TEAMS_ROLES_EDIT: "/admin/teams/roles/edit/:roleId",
    ADMIN_TEAMS_ROLES: "/admin/teams/roles",
    ADMIN_TEAMS_ROLES_CREATE: "/admin/teams/roles/create",

    /* Unity */
    UNITY_HOME: "/client",
    UNITY_LOGIN: "/client/login",
    UNITY_LOGOUT: "/client/logout",
    UNITY_SESSIONS: "/client/sessions",
    UNITY_BUILDER_MODE: "/client/builder-mode",
    UNITY_SCENES: "/client/edit/scene",
    UNITY_ACTIVITIES: "/client/edit/activities",
    UNITY_SLIDES_BASE: "/client/edit/slides/",
    UNITY_SLIDES: "/client/edit/slides/:activityId",
    UNITY_RUN_SESSION: "/session/view",
    UNITY_EDIT_SCENE: "/terrains/view",
    UNITY_EDIT_SLIDE: "/slide/view",
    UNITY_RENDER_SLIDE: "/client/slide/render-markdown/:slideId",
    UNITY_LIBRARY: "/client/library",

}

export default RoutesDef