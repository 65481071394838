import React, { useState, useContext, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";
import { Column, usePagination, useTable } from "react-table";
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Table } from "react-bootstrap";
import { LangContext } from "src/lang/lang";
import { BootstrapTextInput } from "../BootstrapFormComponents";
import { faAngleDown, faAngleUp, faBan, faCross, faRemoveFormat, faSearch, faTrash, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PaginationState = {
    pageIndex: number;
    pageSize: number;
};

type PaginationProps = {
    initialState?: PaginationState;
    itemCount: number;
    allowedPageSizes?: number[];
};

type SortableProps = {
    name: string;
    accessor: string;
};

export interface TableWithPaginationProps<D extends object>
    extends PaginationProps {
    columns: Array<Column<D>>;
    data: D[];
    fetchData: (pageIndex: number, pageSize: number, filter: string, sort: string) => void;
    loading: boolean;
    enableFiltering?: boolean;
    filteringProp?: string;
    sortableProps?: Array<SortableProps>;
    defaultSort?: string;
}

export type SortableColumn<D extends object = {}> =
    | Column<D> & { sortable?: string };

function TableWithPagination<D extends object = {}>(
    props: TableWithPaginationProps<D>,
) {
    const { Sentences } = useContext(LangContext);

    // Use the state and functions returned from useTable to build your UI
    const initialState = props.initialState ?? { pageIndex: 0, pageSize: 10 };
    const allowedPageSizes = props.allowedPageSizes ?? [2, 10, 20, 30, 40, 50];

    const [fetchedPageSize, setFetchedPageSize] = useState(
        initialState.pageSize,
    );

    const [filterInputValue, setFilterInputValue] = React.useState("");
    const [filter, setFilter] = React.useState("");
    const [sort, setSort] = React.useState(props.defaultSort ?? "");
    const [currentSortDirection, setCurrentSortDirection] = React.useState(props.defaultSort?.split(':')[1] ?? 'asc');


    const instance = useTable(
        {
            columns: props.columns,
            data: props.data,
            initialState: initialState,
            manualPagination: true,
            pageCount: Math.ceil(props.itemCount / fetchedPageSize),
        },
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = instance;

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        props.fetchData(pageIndex, pageSize, filter, sort);
        setFetchedPageSize(pageSize);
    }, [pageIndex, pageSize, filter, sort, props]);

    const hasPages = pageOptions.length;
    const paginationItemCount = 5;
    const halfPaginationItemCount = Math.floor(paginationItemCount / 2);
    let paginationPages = [];
    const showingAllPaginationPages =
        pageIndex + halfPaginationItemCount + 1 >= pageCount;

    for (
        let i = Math.max(1, pageIndex - halfPaginationItemCount);
        i <
        Math.max(
            paginationItemCount,
            pageIndex + halfPaginationItemCount + 1,
        ) && i < pageCount;
        i++
    ) {
        paginationPages.push(i);
    }

    const debounceFilterQuery = useMemo(
        () => _.debounce((input) => {
            const p = props.filteringProp ? props.filteringProp + ':like:' : 'name:like:'
            const f = input ? p + input : ''
            gotoPage(0);
            setFilter(f);
        }, 500),
        []
    );

    const handleFilterChange = (event: any) => {
        const input = event.target.value;
        setFilterInputValue(input);
        debounceFilterQuery(input);
    };

    const sortByColumn = (columnName: string) => {
        if (props.sortableProps) {
            const columnProps = props.sortableProps.filter((x) => x.name.toLowerCase() == columnName.toLowerCase())
            const property = columnProps[0].accessor
            const newSortDirection = currentSortDirection == 'asc' ? 'desc' : 'asc';
            setCurrentSortDirection(newSortDirection);
            setSort(property + ':' + newSortDirection);
        }
    }

    const isSortable = (columnName: string) => {
        if (props.sortableProps) {
            const sortableColumns = props.sortableProps.map(a => a.name.toLowerCase());
            return sortableColumns.includes(columnName.toLowerCase());
        }
        return false;
    }

    const isSorted = (columnName: string) => {
        if (props.sortableProps) {
            const columnProps = props.sortableProps.filter((x) => x.name.toLowerCase() == columnName.toLowerCase())
            const property = columnProps[0].accessor
            return sort.startsWith(property)
        }
        return false;
    }

    // Render the UI for your table
    return (
        <div>
            {props.enableFiltering &&

                <Row className="mt-4 flex justify-content-end">
                    <Col xs="auto" >
                        <InputGroup className="my-2">
                            <FormControl
                                placeholder={"Filter by " + (props.filteringProp ? props.filteringProp : 'name') + "..."}
                                onChange={handleFilterChange}
                                value={filterInputValue}
                            />
                            <InputGroup.Append onClick={() => { setFilterInputValue(''); setFilter('') }} className="cursor-pointer" >
                                <InputGroup.Text >
                                    {filter ? (<FontAwesomeIcon icon={faWindowClose} color="#eb4848" />) : (<FontAwesomeIcon icon={faSearch} color="#939498" />)}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            }
            <Table striped {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps({
                                        style: { width: column.width },
                                    })}
                                >
                                    <Row className="cursor-pointer" onClick={() => { if (isSortable(column.Header as string)) sortByColumn(column.Header as string) }}>
                                        <Col>
                                            {column.render("Header")}
                                            {isSortable(column.Header as string) && (
                                                <FontAwesomeIcon icon={currentSortDirection == 'asc' && isSorted(column.Header as string) ? faAngleUp : faAngleDown} color={isSorted(column.Header as string) ? "#fff" : "#6a6a6f"} className="ml-3" />
                                            )}
                                        </Col>
                                    </Row>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    <tr>
                        {props.loading ? (
                            <td colSpan={2}>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </td>
                        ) : null}
                    </tr>
                </tbody>
            </Table>

            <div className="row justify-content-between align-items-center footer">
                <div className="col-4">
                    <Pagination>
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        {hasPages ? (
                            <Pagination.Item
                                onClick={() => gotoPage(0)}
                                active={pageIndex === 0}
                            >
                                {1}
                            </Pagination.Item>
                        ) : null}
                        {pageIndex - halfPaginationItemCount > 1 ? (
                            <Pagination.Ellipsis />
                        ) : null}
                        {paginationPages.map((page) => (
                            <Pagination.Item
                                onClick={() => gotoPage(page)}
                                active={pageIndex === page}
                                key={page}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}
                        {!showingAllPaginationPages ? (
                            <Pagination.Ellipsis />
                        ) : null}
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </div>
                <div className="col-4 text-right">
                    {Sentences.itemPerPages.en}
                    <select
                        className="ml-2"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {allowedPageSizes.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default TableWithPagination;
