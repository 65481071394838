import { createApi } from "@reduxjs/toolkit/query/react";
import { CustomBaseQuery } from "./custom-base-query";
export const api = createApi({
  baseQuery: CustomBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    getBackendVersion: build.query<
      GetBackendVersionApiResponse,
      GetBackendVersionApiArg
    >({
      query: () => ({ url: `/api/version` }),
    }),
    getClientAppStatus: build.mutation<
      GetClientAppStatusApiResponse,
      GetClientAppStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/client-status`,
        method: "POST",
        body: queryArg.clientAppStatusDto,
      }),
    }),
    authControllerGetAuthMethods: build.query<
      AuthControllerGetAuthMethodsApiResponse,
      AuthControllerGetAuthMethodsApiArg
    >({
      query: () => ({ url: `/api/auth/methods` }),
    }),
    authControllerOldVersionLogin: build.mutation<
      AuthControllerOldVersionLoginApiResponse,
      AuthControllerOldVersionLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/local-login`,
        method: "POST",
        body: queryArg.loginUserDto,
      }),
    }),
    authControllerLocalLogin: build.mutation<
      AuthControllerLocalLoginApiResponse,
      AuthControllerLocalLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/login`,
        method: "POST",
        body: queryArg.loginUserDto,
      }),
    }),
    validAcceptInviteLink: build.mutation<
      ValidAcceptInviteLinkApiResponse,
      ValidAcceptInviteLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/${queryArg.id}/valid-accept-invite-link`,
        method: "POST",
        body: queryArg.validInvitationDto,
      }),
    }),
    validPasswordResetLink: build.mutation<
      ValidPasswordResetLinkApiResponse,
      ValidPasswordResetLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/${queryArg.id}/valid-password-reset-link`,
        method: "POST",
        body: queryArg.validInvitationDto,
      }),
    }),
    resetPassword: build.mutation<
      ResetPasswordApiResponse,
      ResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/${queryArg.id}/reset-password`,
        method: "POST",
        body: queryArg.resetLocalPasswordDto,
      }),
    }),
    acceptTeamInvitation: build.mutation<
      AcceptTeamInvitationApiResponse,
      AcceptTeamInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/${queryArg.id}/accept-team-invitation`,
        method: "POST",
        body: queryArg.inviteUpdateLocalUserDto,
      }),
    }),
    recoverAccount: build.mutation<
      RecoverAccountApiResponse,
      RecoverAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/recover-account`,
        method: "POST",
        body: queryArg.recoverAccountDto,
      }),
    }),
    authControllerAuthStatus: build.query<
      AuthControllerAuthStatusApiResponse,
      AuthControllerAuthStatusApiArg
    >({
      query: () => ({ url: `/api/auth/status` }),
    }),
    authControllerGetAbilities: build.query<
      AuthControllerGetAbilitiesApiResponse,
      AuthControllerGetAbilitiesApiArg
    >({
      query: () => ({ url: `/api/auth/abilities` }),
    }),
    authControllerLogout: build.mutation<
      AuthControllerLogoutApiResponse,
      AuthControllerLogoutApiArg
    >({
      query: () => ({ url: `/api/auth/logout`, method: "POST" }),
    }),
    authControllerRefreshToken: build.query<
      AuthControllerRefreshTokenApiResponse,
      AuthControllerRefreshTokenApiArg
    >({
      query: () => ({ url: `/api/auth/refreshToken` }),
    }),
    socialGoogleLogin: build.query<
      SocialGoogleLoginApiResponse,
      SocialGoogleLoginApiArg
    >({
      query: () => ({ url: `/api/auth/social-login/google` }),
    }),
    socialGoogleLoginRedirect: build.query<
      SocialGoogleLoginRedirectApiResponse,
      SocialGoogleLoginRedirectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/social-login/google/callback`,
        params: {
          authuser: queryArg.authuser,
          code: queryArg.code,
          prompt: queryArg.prompt,
          scope: queryArg.scope,
        },
      }),
    }),
    socialGoogleIdLogin: build.query<
      SocialGoogleIdLoginApiResponse,
      SocialGoogleIdLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/social-login/google-id-login/${queryArg.authid}`,
      }),
    }),
    socialGoogleIdLoginRedirect: build.query<
      SocialGoogleIdLoginRedirectApiResponse,
      SocialGoogleIdLoginRedirectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/social-login/google-id/callback`,
        params: {
          authuser: queryArg.authuser,
          code: queryArg.code,
          prompt: queryArg.prompt,
          scope: queryArg.scope,
        },
      }),
    }),
    socialCasLogin: build.query<
      SocialCasLoginApiResponse,
      SocialCasLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/social-login/cas`,
        params: { ticket: queryArg.ticket, id: queryArg.id },
      }),
    }),
    socialCasLoginWithId: build.query<
      SocialCasLoginWithIdApiResponse,
      SocialCasLoginWithIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/social-login/cas-id/${queryArg.authid}`,
        params: { ticket: queryArg.ticket, id: queryArg.id },
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/user`,
        method: "POST",
        body: queryArg.createUserDto,
      }),
    }),
    findAllUsers: build.query<FindAllUsersApiResponse, FindAllUsersApiArg>({
      query: () => ({ url: `/api/user` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/user/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateUserDto,
      }),
    }),
    findOneUser: build.query<FindOneUserApiResponse, FindOneUserApiArg>({
      query: (queryArg) => ({ url: `/api/user/${queryArg.id}` }),
    }),
    removeUser: build.mutation<RemoveUserApiResponse, RemoveUserApiArg>({
      query: (queryArg) => ({
        url: `/api/user/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    findAllUsersPaginated: build.query<
      FindAllUsersPaginatedApiResponse,
      FindAllUsersPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/paginated`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    getUserAuthProviders: build.query<
      GetUserAuthProvidersApiResponse,
      GetUserAuthProvidersApiArg
    >({
      query: (queryArg) => ({ url: `/api/user/${queryArg.id}/authProviders` }),
    }),
    removeAuthProvider: build.mutation<
      RemoveAuthProviderApiResponse,
      RemoveAuthProviderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/${queryArg.id}/authProvider/${queryArg.authProviderId}`,
        method: "DELETE",
      }),
    }),
    addLocalPassword: build.mutation<
      AddLocalPasswordApiResponse,
      AddLocalPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/${queryArg.id}/add-local-password`,
        method: "POST",
        body: queryArg.addPasswordDto,
      }),
    }),
    addSocialAccount: build.mutation<
      AddSocialAccountApiResponse,
      AddSocialAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/${queryArg.id}/add-social-account`,
        method: "POST",
        body: queryArg.addSocialAccountDto,
      }),
    }),
    createLocalUser: build.mutation<
      CreateLocalUserApiResponse,
      CreateLocalUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user-local`,
        method: "POST",
        body: queryArg.createLocalUserDto,
      }),
    }),
    updateMineUser: build.mutation<
      UpdateMineUserApiResponse,
      UpdateMineUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/my/profile/update`,
        method: "PATCH",
        body: queryArg.updateUserDto,
      }),
    }),
    findMineUser: build.query<FindMineUserApiResponse, FindMineUserApiArg>({
      query: () => ({ url: `/api/my/profile` }),
    }),
    getMyAuthProviders: build.query<
      GetMyAuthProvidersApiResponse,
      GetMyAuthProvidersApiArg
    >({
      query: () => ({ url: `/api/my/profile/authProviders` }),
    }),
    removeMyAuthProvider: build.mutation<
      RemoveMyAuthProviderApiResponse,
      RemoveMyAuthProviderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/my/profile/authProvider/${queryArg.authProviderId}`,
        method: "DELETE",
      }),
    }),
    addMyLocalPassword: build.mutation<
      AddMyLocalPasswordApiResponse,
      AddMyLocalPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/my/profile/add-local-password`,
        method: "POST",
        body: queryArg.addPasswordDto,
      }),
    }),
    addMySocialAccount: build.mutation<
      AddMySocialAccountApiResponse,
      AddMySocialAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/my/profile/add-social-account`,
        method: "POST",
        body: queryArg.addSocialAccountDto,
      }),
    }),
    teamControllerCreate: build.mutation<
      TeamControllerCreateApiResponse,
      TeamControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team`,
        method: "POST",
        body: queryArg.createTeamDto,
      }),
    }),
    teamControllerFindAll: build.query<
      TeamControllerFindAllApiResponse,
      TeamControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team`,
        params: { offset: queryArg.offset, limit: queryArg.limit },
      }),
    }),
    teamControllerFindOne: build.query<
      TeamControllerFindOneApiResponse,
      TeamControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/team/${queryArg.teamId}` }),
    }),
    teamControllerUpdate: build.mutation<
      TeamControllerUpdateApiResponse,
      TeamControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}`,
        method: "PATCH",
        body: queryArg.updateTeamDto,
      }),
    }),
    teamControllerRemove: build.mutation<
      TeamControllerRemoveApiResponse,
      TeamControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}`,
        method: "DELETE",
      }),
    }),
    membershipControllerAddMembers: build.mutation<
      MembershipControllerAddMembersApiResponse,
      MembershipControllerAddMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/members`,
        method: "POST",
        body: queryArg.createTeamMembershipDto,
      }),
    }),
    membershipControllerGetMembers: build.query<
      MembershipControllerGetMembersApiResponse,
      MembershipControllerGetMembersApiArg
    >({
      query: (queryArg) => ({ url: `/api/team/${queryArg.teamId}/members` }),
    }),
    membershipControllerInviteMembers: build.mutation<
      MembershipControllerInviteMembersApiResponse,
      MembershipControllerInviteMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/members/invite`,
        method: "POST",
        body: queryArg.teamInviteMembersDto,
      }),
    }),
    membershipControllerGetMember: build.query<
      MembershipControllerGetMemberApiResponse,
      MembershipControllerGetMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/members/${queryArg.userId}`,
      }),
    }),
    membershipControllerRemoveMember: build.mutation<
      MembershipControllerRemoveMemberApiResponse,
      MembershipControllerRemoveMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/members/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    membershipControllerUpdateMember: build.mutation<
      MembershipControllerUpdateMemberApiResponse,
      MembershipControllerUpdateMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/members/${queryArg.userId}`,
        method: "PATCH",
        body: queryArg.updateTeamMembershipDto,
      }),
    }),
    teamRoleControllerCreateRole: build.mutation<
      TeamRoleControllerCreateRoleApiResponse,
      TeamRoleControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/role`,
        method: "POST",
        body: queryArg.createTeamRoleDto,
      }),
    }),
    teamRoleControllerGetTeamRoles: build.query<
      TeamRoleControllerGetTeamRolesApiResponse,
      TeamRoleControllerGetTeamRolesApiArg
    >({
      query: (queryArg) => ({ url: `/api/team/${queryArg.teamId}/role` }),
    }),
    teamRoleControllerUpdateRole: build.mutation<
      TeamRoleControllerUpdateRoleApiResponse,
      TeamRoleControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/team/${queryArg.teamId}/role/${queryArg.roleId}`,
        method: "PATCH",
        body: queryArg.updateTeamRoleDto,
      }),
    }),
    myTeamsControllerFindMine: build.query<
      MyTeamsControllerFindMineApiResponse,
      MyTeamsControllerFindMineApiArg
    >({
      query: (queryArg) => ({ url: `/api/my/teams` }),
    }),
    myTeamsControllerFindOneWithUserMembership: build.query<
      MyTeamsControllerFindOneWithUserMembershipApiResponse,
      MyTeamsControllerFindOneWithUserMembershipApiArg
    >({
      query: (queryArg) => ({ url: `/api/my/teams/${queryArg.teamId}` }),
    }),
    terrainsControllerCreate: build.mutation<
      TerrainsControllerCreateApiResponse,
      TerrainsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain`,
        method: "POST",
        body: queryArg.createTerrainDto,
      }),
    }),
    terrainsControllerFindAllPaginated: build.query<
      TerrainsControllerFindAllPaginatedApiResponse,
      TerrainsControllerFindAllPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          teamId: queryArg.teamId,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    terrainsControllerUpdate: build.mutation<
      TerrainsControllerUpdateApiResponse,
      TerrainsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}`,
        method: "PUT",
        body: queryArg.updateTerrainDto,
      }),
    }),
    terrainsControllerRemove: build.mutation<
      TerrainsControllerRemoveApiResponse,
      TerrainsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}`,
        method: "DELETE",
      }),
    }),
    terrainsControllerFindOne: build.query<
      TerrainsControllerFindOneApiResponse,
      TerrainsControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/terrain/${queryArg.terrainId}` }),
    }),
    terrainsControllerGetTerrainModelLinks: build.query<
      TerrainsControllerGetTerrainModelLinksApiResponse,
      TerrainsControllerGetTerrainModelLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-model-links`,
      }),
    }),
    terrainsControllerDeleteTerrainModelLinks: build.mutation<
      TerrainsControllerDeleteTerrainModelLinksApiResponse,
      TerrainsControllerDeleteTerrainModelLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-model-links`,
        method: "DELETE",
        body: queryArg.deleteTerrainToTerrainModelsDto,
      }),
    }),
    terrainsControllerDeleteTerrainModelLink: build.mutation<
      TerrainsControllerDeleteTerrainModelLinkApiResponse,
      TerrainsControllerDeleteTerrainModelLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-model-links/${queryArg.terrainToTerrainModelId}`,
        method: "DELETE",
      }),
    }),
    terrainsControllerUpdateTerrainModelLink: build.mutation<
      TerrainsControllerUpdateTerrainModelLinkApiResponse,
      TerrainsControllerUpdateTerrainModelLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-model-links/${queryArg.terrainToTerrainModelId}`,
        method: "PATCH",
        body: queryArg.updateTerrainModelLinkDto,
      }),
    }),
    terrainsControllerCreateTerrainModelLink: build.mutation<
      TerrainsControllerCreateTerrainModelLinkApiResponse,
      TerrainsControllerCreateTerrainModelLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-model/${queryArg.terrainModelId}`,
        method: "POST",
      }),
    }),
    terrainsControllerGetTerrainLayers: build.query<
      TerrainsControllerGetTerrainLayersApiResponse,
      TerrainsControllerGetTerrainLayersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-layers`,
      }),
    }),
    terrainsControllerCreateTerrainLayer: build.mutation<
      TerrainsControllerCreateTerrainLayerApiResponse,
      TerrainsControllerCreateTerrainLayerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-layers`,
        method: "POST",
        body: queryArg.createLayerDto,
      }),
    }),
    terrainsControllerCreateTerrainLayerLink: build.mutation<
      TerrainsControllerCreateTerrainLayerLinkApiResponse,
      TerrainsControllerCreateTerrainLayerLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-layers/${queryArg.terrainLayerId}`,
        method: "POST",
      }),
    }),
    terrainsControllerDetachLayer: build.mutation<
      TerrainsControllerDetachLayerApiResponse,
      TerrainsControllerDetachLayerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.terrainId}/terrain-layers/${queryArg.terrainLayerId}`,
        method: "DELETE",
      }),
    }),
    terrainsControllerAddUser: build.mutation<
      TerrainsControllerAddUserApiResponse,
      TerrainsControllerAddUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.id}/access-control`,
        method: "POST",
        body: queryArg.upsertDocumentMembershipDto,
      }),
    }),
    terrainsControllerGetMemberships: build.query<
      TerrainsControllerGetMembershipsApiResponse,
      TerrainsControllerGetMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.id}/access-control`,
      }),
    }),
    terrainsControllerDeleteMembership: build.mutation<
      TerrainsControllerDeleteMembershipApiResponse,
      TerrainsControllerDeleteMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.id}/access-control`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    terrainsControllerChangeVisibility: build.mutation<
      TerrainsControllerChangeVisibilityApiResponse,
      TerrainsControllerChangeVisibilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.id}/visibility`,
        method: "PUT",
        body: queryArg.updateDocumentVisibilityDto,
      }),
    }),
    terrainsControllerChangeTeam: build.mutation<
      TerrainsControllerChangeTeamApiResponse,
      TerrainsControllerChangeTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain/${queryArg.id}/team`,
        method: "PUT",
        body: queryArg.changeTeamOfObjectDto,
      }),
    }),
    layerControllerCreate: build.mutation<
      LayerControllerCreateApiResponse,
      LayerControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers`,
        method: "POST",
        body: queryArg.createLayerDto,
      }),
    }),
    layerControllerFindAll: build.query<
      LayerControllerFindAllApiResponse,
      LayerControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          teamId: queryArg.teamId,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    layerControllerFindOne: build.query<
      LayerControllerFindOneApiResponse,
      LayerControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/layers/${queryArg.layerId}` }),
    }),
    layerControllerUpdate: build.mutation<
      LayerControllerUpdateApiResponse,
      LayerControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.layerId}`,
        method: "PATCH",
        body: queryArg.updateLayerDto,
      }),
    }),
    layerControllerRemove: build.mutation<
      LayerControllerRemoveApiResponse,
      LayerControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.layerId}`,
        method: "DELETE",
      }),
    }),
    layerControllerRetrieveBoundsFromSource: build.query<
      LayerControllerRetrieveBoundsFromSourceApiResponse,
      LayerControllerRetrieveBoundsFromSourceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.layerId}/retrieve-bounds`,
      }),
    }),
    layerControllerAddUser: build.mutation<
      LayerControllerAddUserApiResponse,
      LayerControllerAddUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.id}/access-control`,
        method: "POST",
        body: queryArg.upsertDocumentMembershipDto,
      }),
    }),
    layerControllerGetMemberships: build.query<
      LayerControllerGetMembershipsApiResponse,
      LayerControllerGetMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.id}/access-control`,
      }),
    }),
    layerControllerDeleteMembership: build.mutation<
      LayerControllerDeleteMembershipApiResponse,
      LayerControllerDeleteMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.id}/access-control`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    layerControllerChangeVisibility: build.mutation<
      LayerControllerChangeVisibilityApiResponse,
      LayerControllerChangeVisibilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.id}/visibility`,
        method: "PUT",
        body: queryArg.updateDocumentVisibilityDto,
      }),
    }),
    layerControllerChangeTeam: build.mutation<
      LayerControllerChangeTeamApiResponse,
      LayerControllerChangeTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/layers/${queryArg.id}/team`,
        method: "PUT",
        body: queryArg.changeTeamOfObjectDto,
      }),
    }),
    managedLayerControllerGetAll: build.query<
      ManagedLayerControllerGetAllApiResponse,
      ManagedLayerControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/managed-layers` }),
    }),
    managedLayerControllerGetOne: build.query<
      ManagedLayerControllerGetOneApiResponse,
      ManagedLayerControllerGetOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/managed-layers/${queryArg.layerId}` }),
    }),
    managedLayerControllerUpdate: build.mutation<
      ManagedLayerControllerUpdateApiResponse,
      ManagedLayerControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}`,
        method: "PATCH",
        body: queryArg.updateManagedLayerDto,
      }),
    }),
    managedLayerControllerDelete: build.mutation<
      ManagedLayerControllerDeleteApiResponse,
      ManagedLayerControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}`,
        method: "DELETE",
      }),
    }),
    managedLayerControllerUploadChunk: build.mutation<
      ManagedLayerControllerUploadChunkApiResponse,
      ManagedLayerControllerUploadChunkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/upload-chunk/${queryArg.usedFor}`,
        method: "POST",
        body: queryArg.body,
        params: {
          offset: queryArg.offset,
          totalSize: queryArg.totalSize,
          fileId: queryArg.fileId,
        },
      }),
    }),
    managedLayerControllerDeleteSourceFile: build.mutation<
      ManagedLayerControllerDeleteSourceFileApiResponse,
      ManagedLayerControllerDeleteSourceFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/sourceFile/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    managedLayerControllerDownloadSourceFile: build.query<
      ManagedLayerControllerDownloadSourceFileApiResponse,
      ManagedLayerControllerDownloadSourceFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/sourceFile/${queryArg.fileId}/download`,
      }),
    }),
    managedLayerControllerClearSourceFilesUsedFor: build.mutation<
      ManagedLayerControllerClearSourceFilesUsedForApiResponse,
      ManagedLayerControllerClearSourceFilesUsedForApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/clear-sourcefiles/${queryArg.usedFor}`,
        method: "DELETE",
      }),
    }),
    managedLayerControllerClearTmsFiles: build.mutation<
      ManagedLayerControllerClearTmsFilesApiResponse,
      ManagedLayerControllerClearTmsFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/clear-tmsfiles`,
        method: "DELETE",
      }),
    }),
    managedLayerControllerBuild: build.mutation<
      ManagedLayerControllerBuildApiResponse,
      ManagedLayerControllerBuildApiArg
    >({
      query: (queryArg) => ({
        url: `/api/managed-layers/${queryArg.layerId}/build`,
        method: "POST",
      }),
    }),
    managedLayerSourcesControllerToucheSourceFiles: build.mutation<
      ManagedLayerSourcesControllerToucheSourceFilesApiResponse,
      ManagedLayerSourcesControllerToucheSourceFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ManagedLayerSources/touchFiles`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    jobControllerCreate: build.mutation<
      JobControllerCreateApiResponse,
      JobControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobsBasic`,
        method: "POST",
        body: queryArg.createJobDto,
      }),
    }),
    jobControllerFindAll: build.query<
      JobControllerFindAllApiResponse,
      JobControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/jobsBasic` }),
    }),
    jobControllerFindOne: build.query<
      JobControllerFindOneApiResponse,
      JobControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/jobsBasic/${queryArg.id}` }),
    }),
    jobControllerUpdate: build.mutation<
      JobControllerUpdateApiResponse,
      JobControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobsBasic/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateJobDto,
      }),
    }),
    jobControllerRemove: build.mutation<
      JobControllerRemoveApiResponse,
      JobControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobsBasic/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    startJob: build.mutation<StartJobApiResponse, StartJobApiArg>({
      query: (queryArg) => ({
        url: `/api/jobs/${queryArg.jobId}/start`,
        method: "POST",
      }),
    }),
    stopJob: build.mutation<StopJobApiResponse, StopJobApiArg>({
      query: (queryArg) => ({
        url: `/api/jobs/${queryArg.id}/stop`,
        method: "POST",
      }),
    }),
    jobLifeCycleControllerGetJobStatus: build.query<
      JobLifeCycleControllerGetJobStatusApiResponse,
      JobLifeCycleControllerGetJobStatusApiArg
    >({
      query: (queryArg) => ({ url: `/api/jobs/${queryArg.jobId}/status` }),
    }),
    jobLifeCycleControllerGetJobHistory: build.query<
      JobLifeCycleControllerGetJobHistoryApiResponse,
      JobLifeCycleControllerGetJobHistoryApiArg
    >({
      query: (queryArg) => ({ url: `/api/jobs/${queryArg.jobId}/history` }),
    }),
    jobLifeCycleControllerCleanHistory: build.mutation<
      JobLifeCycleControllerCleanHistoryApiResponse,
      JobLifeCycleControllerCleanHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobs/${queryArg.jobId}/history`,
        method: "DELETE",
      }),
    }),
    jobLifeCycleControllerGetJobLogs: build.query<
      JobLifeCycleControllerGetJobLogsApiResponse,
      JobLifeCycleControllerGetJobLogsApiArg
    >({
      query: (queryArg) => ({ url: `/api/jobs/${queryArg.jobId}/logs` }),
    }),
    jobLifeCycleControllerClearLogs: build.mutation<
      JobLifeCycleControllerClearLogsApiResponse,
      JobLifeCycleControllerClearLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/jobs/${queryArg.jobId}/logs`,
        method: "DELETE",
      }),
    }),
    amqpJobControllerLol: build.query<
      AmqpJobControllerLolApiResponse,
      AmqpJobControllerLolApiArg
    >({
      query: () => ({ url: `/api/amqpJobs/lol` }),
    }),
    documentMembershipControllerGetDocumentMemberships: build.query<
      DocumentMembershipControllerGetDocumentMembershipsApiResponse,
      DocumentMembershipControllerGetDocumentMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/doc-acl`,
        params: {
          documentId: queryArg.documentId,
          documentType: queryArg.documentType,
          teamMembershipId: queryArg.teamMembershipId,
        },
      }),
    }),
    documentMembershipControllerRevokeDocumentMembership: build.mutation<
      DocumentMembershipControllerRevokeDocumentMembershipApiResponse,
      DocumentMembershipControllerRevokeDocumentMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/doc-acl`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    documentRoleControllerGetRoles: build.query<
      DocumentRoleControllerGetRolesApiResponse,
      DocumentRoleControllerGetRolesApiArg
    >({
      query: () => ({ url: `/api/doc-role` }),
    }),
    terrainModelsControllerCreate: build.mutation<
      TerrainModelsControllerCreateApiResponse,
      TerrainModelsControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model`,
        method: "POST",
        body: queryArg.createTerrainModelDto,
      }),
    }),
    terrainModelsControllerGetAll: build.query<
      TerrainModelsControllerGetAllApiResponse,
      TerrainModelsControllerGetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          teamId: queryArg.teamId,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    terrainModelsControllerRemove: build.mutation<
      TerrainModelsControllerRemoveApiResponse,
      TerrainModelsControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}`,
        method: "DELETE",
      }),
    }),
    terrainModelsControllerGetOne: build.query<
      TerrainModelsControllerGetOneApiResponse,
      TerrainModelsControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}`,
      }),
    }),
    terrainModelsControllerUpdate: build.mutation<
      TerrainModelsControllerUpdateApiResponse,
      TerrainModelsControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}`,
        method: "PATCH",
        body: queryArg.updateTerrainModelDto,
      }),
    }),
    terrainModelsControllerAddUser: build.mutation<
      TerrainModelsControllerAddUserApiResponse,
      TerrainModelsControllerAddUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.id}/access-control`,
        method: "POST",
        body: queryArg.upsertDocumentMembershipDto,
      }),
    }),
    terrainModelsControllerGetMemberships: build.query<
      TerrainModelsControllerGetMembershipsApiResponse,
      TerrainModelsControllerGetMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.id}/access-control`,
      }),
    }),
    terrainModelsControllerDeleteMembership: build.mutation<
      TerrainModelsControllerDeleteMembershipApiResponse,
      TerrainModelsControllerDeleteMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.id}/access-control`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    terrainModelsControllerChangeVisibility: build.mutation<
      TerrainModelsControllerChangeVisibilityApiResponse,
      TerrainModelsControllerChangeVisibilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.id}/visibility`,
        method: "PUT",
        body: queryArg.updateDocumentVisibilityDto,
      }),
    }),
    terrainModelsControllerChangeTeam: build.mutation<
      TerrainModelsControllerChangeTeamApiResponse,
      TerrainModelsControllerChangeTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.id}/team`,
        method: "PUT",
        body: queryArg.changeTeamOfObjectDto,
      }),
    }),
    terrainModelFileControllerCreateRevision: build.mutation<
      TerrainModelFileControllerCreateRevisionApiResponse,
      TerrainModelFileControllerCreateRevisionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision`,
        method: "POST",
      }),
    }),
    terrainModelFileControllerFindRevs: build.query<
      TerrainModelFileControllerFindRevsApiResponse,
      TerrainModelFileControllerFindRevsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision`,
      }),
    }),
    terrainModelFileControllerCleanUpRevs: build.mutation<
      TerrainModelFileControllerCleanUpRevsApiResponse,
      TerrainModelFileControllerCleanUpRevsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision`,
        method: "DELETE",
        params: { keepCurrent: queryArg.keepCurrent },
      }),
    }),
    terrainModelFileControllerUploadPart: build.mutation<
      TerrainModelFileControllerUploadPartApiResponse,
      TerrainModelFileControllerUploadPartApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision/${queryArg.revisionId}/upload-part`,
        method: "PUT",
        body: queryArg.body,
        params: { partNumber: queryArg.partNumber },
      }),
    }),
    terrainModelFileControllerCompleteUpload: build.mutation<
      TerrainModelFileControllerCompleteUploadApiResponse,
      TerrainModelFileControllerCompleteUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision/${queryArg.revisionId}/complete-upload`,
        method: "POST",
      }),
    }),
    terrainModelFileControllerFindByRevId: build.query<
      TerrainModelFileControllerFindByRevIdApiResponse,
      TerrainModelFileControllerFindByRevIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision/${queryArg.revisionId}`,
      }),
    }),
    terrainModelFileControllerDeleteRev: build.mutation<
      TerrainModelFileControllerDeleteRevApiResponse,
      TerrainModelFileControllerDeleteRevApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision/${queryArg.revisionId}`,
        method: "DELETE",
      }),
    }),
    terrainModelFileControllerSetCurrent: build.mutation<
      TerrainModelFileControllerSetCurrentApiResponse,
      TerrainModelFileControllerSetCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/terrain-model/${queryArg.terrainModelId}/tiles-revision/${queryArg.revisionId}/set-current`,
        method: "PUT",
      }),
    }),
    terrainModelsCapabilitiesControllerGetCapabilities: build.query<
      TerrainModelsCapabilitiesControllerGetCapabilitiesApiResponse,
      TerrainModelsCapabilitiesControllerGetCapabilitiesApiArg
    >({
      query: () => ({ url: `/api/terrain-model-capabilities` }),
    }),
    activitiesControllerCreate: build.mutation<
      ActivitiesControllerCreateApiResponse,
      ActivitiesControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities`,
        method: "POST",
        body: queryArg.createActivityDto,
      }),
    }),
    activitiesControllerFindAllPaginated: build.query<
      ActivitiesControllerFindAllPaginatedApiResponse,
      ActivitiesControllerFindAllPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          teamId: queryArg.teamId,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    activitiesControllerCreateSlide: build.mutation<
      ActivitiesControllerCreateSlideApiResponse,
      ActivitiesControllerCreateSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}/slides`,
        method: "POST",
        body: queryArg.createSlideDto,
      }),
    }),
    activitiesControllerGetSlides: build.query<
      ActivitiesControllerGetSlidesApiResponse,
      ActivitiesControllerGetSlidesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}/slides`,
      }),
    }),
    activitiesControllerUpdateSlidesOrder: build.mutation<
      ActivitiesControllerUpdateSlidesOrderApiResponse,
      ActivitiesControllerUpdateSlidesOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}/slides`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    activitiesControllerFindOne: build.query<
      ActivitiesControllerFindOneApiResponse,
      ActivitiesControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/activities/${queryArg.activityId}` }),
    }),
    activitiesControllerUpdate: build.mutation<
      ActivitiesControllerUpdateApiResponse,
      ActivitiesControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}`,
        method: "PATCH",
        body: queryArg.updateActivityDto,
      }),
    }),
    activitiesControllerRemove: build.mutation<
      ActivitiesControllerRemoveApiResponse,
      ActivitiesControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}`,
        method: "DELETE",
      }),
    }),
    activitiesControllerGetSlidesSummary: build.query<
      ActivitiesControllerGetSlidesSummaryApiResponse,
      ActivitiesControllerGetSlidesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.activityId}/slides-summary`,
      }),
    }),
    activitiesControllerAddUser: build.mutation<
      ActivitiesControllerAddUserApiResponse,
      ActivitiesControllerAddUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.id}/access-control`,
        method: "POST",
        body: queryArg.upsertDocumentMembershipDto,
      }),
    }),
    activitiesControllerGetMemberships: build.query<
      ActivitiesControllerGetMembershipsApiResponse,
      ActivitiesControllerGetMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.id}/access-control`,
      }),
    }),
    activitiesControllerDeleteMembership: build.mutation<
      ActivitiesControllerDeleteMembershipApiResponse,
      ActivitiesControllerDeleteMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.id}/access-control`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    activitiesControllerChangeVisibility: build.mutation<
      ActivitiesControllerChangeVisibilityApiResponse,
      ActivitiesControllerChangeVisibilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.id}/visibility`,
        method: "PUT",
        body: queryArg.updateDocumentVisibilityDto,
      }),
    }),
    activitiesControllerChangeTeam: build.mutation<
      ActivitiesControllerChangeTeamApiResponse,
      ActivitiesControllerChangeTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activities/${queryArg.id}/team`,
        method: "PUT",
        body: queryArg.changeTeamOfObjectDto,
      }),
    }),
    getAllSlides: build.query<GetAllSlidesApiResponse, GetAllSlidesApiArg>({
      query: () => ({ url: `/api/slides` }),
    }),
    getSlide: build.query<GetSlideApiResponse, GetSlideApiArg>({
      query: (queryArg) => ({ url: `/api/slides/${queryArg.slideId}` }),
    }),
    updateSlide: build.mutation<UpdateSlideApiResponse, UpdateSlideApiArg>({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}`,
        method: "PATCH",
        body: queryArg.updateSlideDto,
      }),
    }),
    deleteSlide: build.mutation<DeleteSlideApiResponse, DeleteSlideApiArg>({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}`,
        method: "DELETE",
      }),
    }),
    slidesControllerCopySlide: build.mutation<
      SlidesControllerCopySlideApiResponse,
      SlidesControllerCopySlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/copy`,
        method: "POST",
      }),
    }),
    slidesControllerLinkTerrain: build.mutation<
      SlidesControllerLinkTerrainApiResponse,
      SlidesControllerLinkTerrainApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/terrain/${queryArg.terrainId}`,
        method: "POST",
      }),
    }),
    unlinkTerrainFromSlide: build.mutation<
      UnlinkTerrainFromSlideApiResponse,
      UnlinkTerrainFromSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/terrain`,
        method: "DELETE",
      }),
    }),
    slidesControllerAddGeofeature: build.mutation<
      SlidesControllerAddGeofeatureApiResponse,
      SlidesControllerAddGeofeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/geofeatures`,
        method: "POST",
        body: queryArg.createGeoFeatureDto,
      }),
    }),
    slidesControllerClearGeofeature: build.mutation<
      SlidesControllerClearGeofeatureApiResponse,
      SlidesControllerClearGeofeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/geofeatures`,
        method: "DELETE",
      }),
    }),
    slidesControllerDeleteGeofeature: build.mutation<
      SlidesControllerDeleteGeofeatureApiResponse,
      SlidesControllerDeleteGeofeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/geofeatures/${queryArg.geoFeatureId}`,
        method: "DELETE",
      }),
    }),
    attachFileToSlide: build.mutation<
      AttachFileToSlideApiResponse,
      AttachFileToSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/files/${queryArg.fileId}`,
        method: "POST",
      }),
    }),
    uploadFileToSlide: build.mutation<
      UploadFileToSlideApiResponse,
      UploadFileToSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/upload-file`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    slidesFilesControllerGetAllMedia: build.query<
      SlidesFilesControllerGetAllMediaApiResponse,
      SlidesFilesControllerGetAllMediaApiArg
    >({
      query: (queryArg) => ({ url: `/api/slides/${queryArg.slideId}/files` }),
    }),
    slidesFilesControllerGetFileStream: build.query<
      SlidesFilesControllerGetFileStreamApiResponse,
      SlidesFilesControllerGetFileStreamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/files/${queryArg.fileId}/content`,
      }),
    }),
    deleteFileFromSlide: build.mutation<
      DeleteFileFromSlideApiResponse,
      DeleteFileFromSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/files/${queryArg.fileId}/content`,
        method: "DELETE",
      }),
    }),
    detachFileFromSlide: build.mutation<
      DetachFileFromSlideApiResponse,
      DetachFileFromSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/files/${queryArg.fileId}/link`,
        method: "DELETE",
      }),
    }),
    slidesFilesControllerUploadGeoJsonToSlide: build.mutation<
      SlidesFilesControllerUploadGeoJsonToSlideApiResponse,
      SlidesFilesControllerUploadGeoJsonToSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slides/${queryArg.slideId}/upload-geojson`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    downloadedFilesControllerUpload: build.mutation<
      DownloadedFilesControllerUploadApiResponse,
      DownloadedFilesControllerUploadApiArg
    >({
      query: () => ({ url: `/api/files`, method: "POST" }),
    }),
    downloadedFilesControllerFindAll: build.query<
      DownloadedFilesControllerFindAllApiResponse,
      DownloadedFilesControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/files` }),
    }),
    downloadedFilesControllerFindOne: build.query<
      DownloadedFilesControllerFindOneApiResponse,
      DownloadedFilesControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/files/${queryArg.id}` }),
    }),
    downloadedFilesControllerRemove: build.mutation<
      DownloadedFilesControllerRemoveApiResponse,
      DownloadedFilesControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    courseSessionControllerCreate: build.mutation<
      CourseSessionControllerCreateApiResponse,
      CourseSessionControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session`,
        method: "POST",
        body: queryArg.createCourseSessionDto,
      }),
    }),
    courseSessionControllerFindAll: build.query<
      CourseSessionControllerFindAllApiResponse,
      CourseSessionControllerFindAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session`,
        params: {
          mine: queryArg.mine,
          enrolled: queryArg.enrolled,
          teamId: queryArg.teamId,
        },
      }),
    }),
    courseSessionControllerFindAllPaginated: build.query<
      CourseSessionControllerFindAllPaginatedApiResponse,
      CourseSessionControllerFindAllPaginatedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/paginated`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          mine: queryArg.mine,
          enrolled: queryArg.enrolled,
          teamId: queryArg.teamId,
          filter: queryArg.filter,
          sort: queryArg.sort,
        },
      }),
    }),
    courseSessionControllerFindOne: build.query<
      CourseSessionControllerFindOneApiResponse,
      CourseSessionControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}`,
      }),
    }),
    courseSessionControllerUpdate: build.mutation<
      CourseSessionControllerUpdateApiResponse,
      CourseSessionControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}`,
        method: "PATCH",
        body: queryArg.updateCourseSessionDto,
      }),
    }),
    courseSessionControllerRemove: build.mutation<
      CourseSessionControllerRemoveApiResponse,
      CourseSessionControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}`,
        method: "DELETE",
      }),
    }),
    courseSessionControllerGetMyRole: build.query<
      CourseSessionControllerGetMyRoleApiResponse,
      CourseSessionControllerGetMyRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/my-role`,
      }),
    }),
    courseSessionControllerGetActivities: build.query<
      CourseSessionControllerGetActivitiesApiResponse,
      CourseSessionControllerGetActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activities`,
      }),
    }),
    courseSessionControllerCreateAndConnectedActivity: build.mutation<
      CourseSessionControllerCreateAndConnectedActivityApiResponse,
      CourseSessionControllerCreateAndConnectedActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activities`,
        method: "POST",
      }),
    }),
    courseSessionControllerUpdateActivities: build.mutation<
      CourseSessionControllerUpdateActivitiesApiResponse,
      CourseSessionControllerUpdateActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activities`,
        method: "PATCH",
        body: queryArg.updateCourseSessionActivitiesDto,
      }),
    }),
    courseSessionControllerConnectActivity: build.mutation<
      CourseSessionControllerConnectActivityApiResponse,
      CourseSessionControllerConnectActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activities/${queryArg.activityId}`,
        method: "POST",
      }),
    }),
    courseSessionControllerSetCurrentMode: build.mutation<
      CourseSessionControllerSetCurrentModeApiResponse,
      CourseSessionControllerSetCurrentModeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/current-mode`,
        method: "POST",
        body: queryArg.updateCurrentModeDto,
      }),
    }),
    courseSessionControllerDisconnectActivity: build.mutation<
      CourseSessionControllerDisconnectActivityApiResponse,
      CourseSessionControllerDisconnectActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}`,
        method: "DELETE",
      }),
    }),
    courseSessionControllerGetOneActivityProgress: build.query<
      CourseSessionControllerGetOneActivityProgressApiResponse,
      CourseSessionControllerGetOneActivityProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}`,
      }),
    }),
    courseSessionControllerGetAllActivityProgress: build.query<
      CourseSessionControllerGetAllActivityProgressApiResponse,
      CourseSessionControllerGetAllActivityProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress`,
      }),
    }),
    courseSessionControllerGetSlidesSummaryForActivityProgress: build.query<
      CourseSessionControllerGetSlidesSummaryForActivityProgressApiResponse,
      CourseSessionControllerGetSlidesSummaryForActivityProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slides-summary`,
      }),
    }),
    courseSessionControllerGetAllGroupsForCourseSession: build.query<
      CourseSessionControllerGetAllGroupsForCourseSessionApiResponse,
      CourseSessionControllerGetAllGroupsForCourseSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/my-groups`,
      }),
    }),
    courseSessionControllerGetAllSlideProgressRecordsForActivityProgress:
      build.query<
        CourseSessionControllerGetAllSlideProgressRecordsForActivityProgressApiResponse,
        CourseSessionControllerGetAllSlideProgressRecordsForActivityProgressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide-progress`,
        }),
      }),
    courseSessionControllerGetCurrentGroupForUser: build.query<
      CourseSessionControllerGetCurrentGroupForUserApiResponse,
      CourseSessionControllerGetCurrentGroupForUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/my-current-group`,
      }),
    }),
    courseSessionControllerGetCurrentStateForGroup: build.query<
      CourseSessionControllerGetCurrentStateForGroupApiResponse,
      CourseSessionControllerGetCurrentStateForGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/current-group-state/${queryArg.groupId}`,
      }),
    }),
    courseSessionControllerSetCurrentStateForGroup: build.mutation<
      CourseSessionControllerSetCurrentStateForGroupApiResponse,
      CourseSessionControllerSetCurrentStateForGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/current-group-state/${queryArg.groupId}`,
        method: "POST",
      }),
    }),
    courseSessionControllerGetAllSlideProgressRecordsForSlide: build.query<
      CourseSessionControllerGetAllSlideProgressRecordsForSlideApiResponse,
      CourseSessionControllerGetAllSlideProgressRecordsForSlideApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}`,
      }),
    }),
    courseSessionControllerGetSlideProgressForGroup: build.query<
      CourseSessionControllerGetSlideProgressForGroupApiResponse,
      CourseSessionControllerGetSlideProgressForGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}/${queryArg.groupId}`,
      }),
    }),
    courseSessionControllerAddFileToSlideProgress: build.mutation<
      CourseSessionControllerAddFileToSlideProgressApiResponse,
      CourseSessionControllerAddFileToSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}/${queryArg.groupId}/file/${queryArg.fileId}`,
        method: "POST",
      }),
    }),
    courseSessionControllerDetachFileFromSlideProgress: build.mutation<
      CourseSessionControllerDetachFileFromSlideProgressApiResponse,
      CourseSessionControllerDetachFileFromSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}/${queryArg.groupId}/file/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    courseSessionControllerUploadFileToSlideProgress: build.mutation<
      CourseSessionControllerUploadFileToSlideProgressApiResponse,
      CourseSessionControllerUploadFileToSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}/${queryArg.groupId}/upload-file`,
        method: "POST",
      }),
    }),
    courseSessionControllerGetFilesOfSlideProgress: build.query<
      CourseSessionControllerGetFilesOfSlideProgressApiResponse,
      CourseSessionControllerGetFilesOfSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide/${queryArg.slideId}/${queryArg.groupId}/file`,
      }),
    }),
    courseSessionControllerResetSession: build.mutation<
      CourseSessionControllerResetSessionApiResponse,
      CourseSessionControllerResetSessionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/reset`,
        method: "DELETE",
      }),
    }),
    courseSessionControllerSetOwner: build.mutation<
      CourseSessionControllerSetOwnerApiResponse,
      CourseSessionControllerSetOwnerApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/owner/${queryArg.userId}`,
        method: "POST",
      }),
    }),
    courseSessionControllerAddUser: build.mutation<
      CourseSessionControllerAddUserApiResponse,
      CourseSessionControllerAddUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.id}/access-control`,
        method: "POST",
        body: queryArg.upsertDocumentMembershipDto,
      }),
    }),
    courseSessionControllerGetMemberships: build.query<
      CourseSessionControllerGetMembershipsApiResponse,
      CourseSessionControllerGetMembershipsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.id}/access-control`,
      }),
    }),
    courseSessionControllerDeleteMembership: build.mutation<
      CourseSessionControllerDeleteMembershipApiResponse,
      CourseSessionControllerDeleteMembershipApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.id}/access-control`,
        method: "DELETE",
        body: queryArg.findOneDocumentMembershipDto,
      }),
    }),
    courseSessionControllerChangeVisibility: build.mutation<
      CourseSessionControllerChangeVisibilityApiResponse,
      CourseSessionControllerChangeVisibilityApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.id}/visibility`,
        method: "PUT",
        body: queryArg.updateDocumentVisibilityDto,
      }),
    }),
    courseSessionControllerChangeTeam: build.mutation<
      CourseSessionControllerChangeTeamApiResponse,
      CourseSessionControllerChangeTeamApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.id}/team`,
        method: "PUT",
        body: queryArg.changeTeamOfObjectDto,
      }),
    }),
    courseSessionStatsControllerGetActivityStats: build.query<
      CourseSessionStatsControllerGetActivityStatsApiResponse,
      CourseSessionStatsControllerGetActivityStatsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress/${queryArg.activityProgressId}/slide-stats`,
      }),
    }),
    courseSessionStatsControllerGetActivityProgressSummaries: build.query<
      CourseSessionStatsControllerGetActivityProgressSummariesApiResponse,
      CourseSessionStatsControllerGetActivityProgressSummariesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/activity-progress-summaries`,
      }),
    }),
    courseSessionStatsControllerGetCurrentGroupProgress: build.query<
      CourseSessionStatsControllerGetCurrentGroupProgressApiResponse,
      CourseSessionStatsControllerGetCurrentGroupProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/current-group-progress/${queryArg.groupId}`,
      }),
    }),
    courseSessionStatsControllerGetAllGroupLayoutProgress: build.query<
      CourseSessionStatsControllerGetAllGroupLayoutProgressApiResponse,
      CourseSessionStatsControllerGetAllGroupLayoutProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/group-layout-progress`,
      }),
    }),
    courseSessionStatsControllerGetOneGroupLayoutProgress: build.query<
      CourseSessionStatsControllerGetOneGroupLayoutProgressApiResponse,
      CourseSessionStatsControllerGetOneGroupLayoutProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/group-layout-progress/${queryArg.groupLayoutId}`,
      }),
    }),
    courseSessionStatsControllerGetCurrentGroupLayoutProgress: build.query<
      CourseSessionStatsControllerGetCurrentGroupLayoutProgressApiResponse,
      CourseSessionStatsControllerGetCurrentGroupLayoutProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/current-group-layout-progress`,
      }),
    }),
    courseSessionStudentsControllerAddStudent: build.mutation<
      CourseSessionStudentsControllerAddStudentApiResponse,
      CourseSessionStudentsControllerAddStudentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/students/${queryArg.studentId}`,
        method: "POST",
      }),
    }),
    courseSessionStudentsControllerRemoveStudent: build.mutation<
      CourseSessionStudentsControllerRemoveStudentApiResponse,
      CourseSessionStudentsControllerRemoveStudentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/students/${queryArg.studentId}`,
        method: "DELETE",
      }),
    }),
    courseSessionStudentsControllerGetAllStudents: build.query<
      CourseSessionStudentsControllerGetAllStudentsApiResponse,
      CourseSessionStudentsControllerGetAllStudentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-session/${queryArg.courseSessionId}/students`,
      }),
    }),
    createCourseGroup: build.mutation<
      CreateCourseGroupApiResponse,
      CreateCourseGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-groups`,
        method: "POST",
        body: queryArg.createCourseGroupDto,
      }),
    }),
    getAllCourseGroups: build.query<
      GetAllCourseGroupsApiResponse,
      GetAllCourseGroupsApiArg
    >({
      query: () => ({ url: `/api/course-groups` }),
    }),
    getCourseGroup: build.query<
      GetCourseGroupApiResponse,
      GetCourseGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-groups/${queryArg.courseGroupId}`,
      }),
    }),
    updateCourseGroup: build.mutation<
      UpdateCourseGroupApiResponse,
      UpdateCourseGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-groups/${queryArg.courseGroupId}`,
        method: "PATCH",
        body: queryArg.createCourseGroupDto,
      }),
    }),
    deleteCourseGroup: build.mutation<
      DeleteCourseGroupApiResponse,
      DeleteCourseGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/course-groups/${queryArg.courseGroupId}`,
        method: "DELETE",
      }),
    }),
    slideProgressControllerFindOne: build.query<
      SlideProgressControllerFindOneApiResponse,
      SlideProgressControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}`,
      }),
    }),
    slideProgressControllerUpdate: build.mutation<
      SlideProgressControllerUpdateApiResponse,
      SlideProgressControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}`,
        method: "PATCH",
        body: queryArg.updateSlideProgressDto,
      }),
    }),
    slideProgressControllerAttachFile: build.mutation<
      SlideProgressControllerAttachFileApiResponse,
      SlideProgressControllerAttachFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/file/${queryArg.fileId}`,
        method: "POST",
      }),
    }),
    slideProgressControllerGetFileData: build.query<
      SlideProgressControllerGetFileDataApiResponse,
      SlideProgressControllerGetFileDataApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/file/${queryArg.fileId}`,
      }),
    }),
    slideProgressControllerDetachFile: build.mutation<
      SlideProgressControllerDetachFileApiResponse,
      SlideProgressControllerDetachFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/file/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    slideProgressControllerUploadFile: build.mutation<
      SlideProgressControllerUploadFileApiResponse,
      SlideProgressControllerUploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/upload-file`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    slideProgressControllerDeleteFile: build.mutation<
      SlideProgressControllerDeleteFileApiResponse,
      SlideProgressControllerDeleteFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/delete-file/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    slideProgressControllerGetFiles: build.query<
      SlideProgressControllerGetFilesApiResponse,
      SlideProgressControllerGetFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/file`,
      }),
    }),
    slideProgressControllerCreateGeoFeature: build.mutation<
      SlideProgressControllerCreateGeoFeatureApiResponse,
      SlideProgressControllerCreateGeoFeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/geofeature`,
        method: "POST",
        body: queryArg.createGeoFeatureDto,
      }),
    }),
    slideProgressControllerGetAllGeoFeatures: build.query<
      SlideProgressControllerGetAllGeoFeaturesApiResponse,
      SlideProgressControllerGetAllGeoFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/geofeature`,
      }),
    }),
    slideProgressControllerGetAllSlideGeoFeatures: build.query<
      SlideProgressControllerGetAllSlideGeoFeaturesApiResponse,
      SlideProgressControllerGetAllSlideGeoFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/slide-geofeature`,
      }),
    }),
    slideProgressControllerDeleteGeoFeature: build.mutation<
      SlideProgressControllerDeleteGeoFeatureApiResponse,
      SlideProgressControllerDeleteGeoFeatureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/slide-progress/${queryArg.slideProgressId}/geofeature/${queryArg.geoFeatureId}`,
        method: "DELETE",
      }),
    }),
    activityProgressControllerCreate: build.mutation<
      ActivityProgressControllerCreateApiResponse,
      ActivityProgressControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress`,
        method: "POST",
        body: queryArg.createActivityProgressDto,
      }),
    }),
    getAllActivityProgress: build.query<
      GetAllActivityProgressApiResponse,
      GetAllActivityProgressApiArg
    >({
      query: () => ({ url: `/api/activity-progress` }),
    }),
    getActivityProgress: build.query<
      GetActivityProgressApiResponse,
      GetActivityProgressApiArg
    >({
      query: (queryArg) => ({ url: `/api/activity-progress/${queryArg.id}` }),
    }),
    updateActivityProgress: build.mutation<
      UpdateActivityProgressApiResponse,
      UpdateActivityProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateActivityProgressDto,
      }),
    }),
    deleteActivityProgress: build.mutation<
      DeleteActivityProgressApiResponse,
      DeleteActivityProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    activityProgressControllerGetActivityProgressSlides: build.query<
      ActivityProgressControllerGetActivityProgressSlidesApiResponse,
      ActivityProgressControllerGetActivityProgressSlidesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.activityProgressId}/slide-progress`,
      }),
    }),
    activityProgressControllerCreateSlideProgress: build.mutation<
      ActivityProgressControllerCreateSlideProgressApiResponse,
      ActivityProgressControllerCreateSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}`,
        method: "POST",
        body: queryArg.createSlideProgressDto,
      }),
    }),
    activityProgressControllerGetSlideProgress: build.query<
      ActivityProgressControllerGetSlideProgressApiResponse,
      ActivityProgressControllerGetSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}`,
      }),
    }),
    updateSlideProgress: build.mutation<
      UpdateSlideProgressApiResponse,
      UpdateSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}`,
        method: "PATCH",
        body: queryArg.createSlideProgressDto,
      }),
    }),
    activityProgressControllerAddFileToSlideProgress: build.mutation<
      ActivityProgressControllerAddFileToSlideProgressApiResponse,
      ActivityProgressControllerAddFileToSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}/file/${queryArg.fileId}`,
        method: "POST",
      }),
    }),
    activityProgressControllerRemoveFileFromSlideProgress: build.mutation<
      ActivityProgressControllerRemoveFileFromSlideProgressApiResponse,
      ActivityProgressControllerRemoveFileFromSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}/file/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    activityProgressControllerUploadFileToSlideProgress: build.mutation<
      ActivityProgressControllerUploadFileToSlideProgressApiResponse,
      ActivityProgressControllerUploadFileToSlideProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/activity-progress/${queryArg.id}/slide-progress/${queryArg.slideId}/${queryArg.groupId}/upload-file`,
        method: "POST",
      }),
    }),
    postAnswer: build.mutation<PostAnswerApiResponse, PostAnswerApiArg>({
      query: (queryArg) => ({
        url: `/api/quiz/${queryArg.questionId}/answer`,
        method: "POST",
      }),
    }),
    updateAnswer: build.mutation<UpdateAnswerApiResponse, UpdateAnswerApiArg>({
      query: (queryArg) => ({
        url: `/api/quiz/${queryArg.questionId}/answer`,
        method: "PUT",
      }),
    }),
    getAnswer: build.query<GetAnswerApiResponse, GetAnswerApiArg>({
      query: (queryArg) => ({
        url: `/api/quiz/${queryArg.questionId}/answer/${queryArg.groupId}`,
      }),
    }),
    artifactsControllerGetPackages: build.query<
      ArtifactsControllerGetPackagesApiResponse,
      ArtifactsControllerGetPackagesApiArg
    >({
      query: () => ({ url: `/api/bin-client/p` }),
    }),
    artifactsControllerGetVersions: build.query<
      ArtifactsControllerGetVersionsApiResponse,
      ArtifactsControllerGetVersionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/v`,
        params: {
          packageName: queryArg.packageName,
          namespace: queryArg["namespace"],
        },
      }),
    }),
    artifactsControllerCreateReleaseChannel: build.mutation<
      ArtifactsControllerCreateReleaseChannelApiResponse,
      ArtifactsControllerCreateReleaseChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/r`,
        method: "POST",
        body: queryArg.createArtifactReleaseDto,
      }),
    }),
    artifactsControllerGetReleaseChannelsPaginated: build.query<
      ArtifactsControllerGetReleaseChannelsPaginatedApiResponse,
      ArtifactsControllerGetReleaseChannelsPaginatedApiArg
    >({
      query: (queryArg) => ({ url: `/api/bin-client/r` }),
    }),
    artifactsControllerGetReleaseChannel: build.query<
      ArtifactsControllerGetReleaseChannelApiResponse,
      ArtifactsControllerGetReleaseChannelApiArg
    >({
      query: (queryArg) => ({ url: `/api/bin-client/r/${queryArg.releaseId}` }),
    }),
    artifactsControllerUpdateRelease: build.mutation<
      ArtifactsControllerUpdateReleaseApiResponse,
      ArtifactsControllerUpdateReleaseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/r/${queryArg.releaseId}`,
        method: "PATCH",
        body: queryArg.updateArtifactReleaseDto,
      }),
    }),
    artifactsControllerDeleteRelease: build.mutation<
      ArtifactsControllerDeleteReleaseApiResponse,
      ArtifactsControllerDeleteReleaseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/r/${queryArg.releaseId}`,
        method: "DELETE",
      }),
    }),
    artifactsControllerGetReleaseRssByNameAndChannel: build.query<
      ArtifactsControllerGetReleaseRssByNameAndChannelApiResponse,
      ArtifactsControllerGetReleaseRssByNameAndChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/rr`,
        params: { name: queryArg.name, channel: queryArg.channel },
      }),
    }),
    artifactsControllerGetArtifactRss: build.query<
      ArtifactsControllerGetArtifactRssApiResponse,
      ArtifactsControllerGetArtifactRssApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/r/${queryArg.releaseId}/rss`,
      }),
    }),
    artifactsControllerGetReleaseAssetFile: build.query<
      ArtifactsControllerGetReleaseAssetFileApiResponse,
      ArtifactsControllerGetReleaseAssetFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bin-client/r/${queryArg.releaseId}/download/${queryArg.artifactName}`,
      }),
    }),
    teamStorageControllerGetStorageStats: build.query<
      TeamStorageControllerGetStorageStatsApiResponse,
      TeamStorageControllerGetStorageStatsApiArg
    >({
      query: (queryArg) => ({ url: `/api/team/${queryArg.teamId}/storage` }),
    }),
  }),
});
export type GetBackendVersionApiResponse = /** status 200  */ VersionInfo;
export type GetBackendVersionApiArg = void;
export type GetClientAppStatusApiResponse = /** status 201  */ ClientAppStatus;
export type GetClientAppStatusApiArg = {
  clientAppStatusDto: ClientAppStatusDto;
};
export type AuthControllerGetAuthMethodsApiResponse =
  /** status 200  */ AuthMethodsDto;
export type AuthControllerGetAuthMethodsApiArg = void;
export type AuthControllerOldVersionLoginApiResponse = unknown;
export type AuthControllerOldVersionLoginApiArg = {
  loginUserDto: LoginUserDto;
};
export type AuthControllerLocalLoginApiResponse =
  /** status 201  */ LoginStatusDto;
export type AuthControllerLocalLoginApiArg = {
  loginUserDto: LoginUserDto;
};
export type ValidAcceptInviteLinkApiResponse = /** status 200  */
  | ResetPasswordLinkIsValid
  | /** status 201  */ ResetPasswordLinkIsValid;
export type ValidAcceptInviteLinkApiArg = {
  id: number;
  validInvitationDto: ValidInvitationDto;
};
export type ValidPasswordResetLinkApiResponse = /** status 200  */
  | ResetPasswordLinkIsValid
  | /** status 201  */ ResetPasswordLinkIsValid;
export type ValidPasswordResetLinkApiArg = {
  id: number;
  validInvitationDto: ValidInvitationDto;
};
export type ResetPasswordApiResponse = /** status 200  */
  | boolean
  | /** status 201  */ object;
export type ResetPasswordApiArg = {
  id: number;
  resetLocalPasswordDto: ResetLocalPasswordDto;
};
export type AcceptTeamInvitationApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type AcceptTeamInvitationApiArg = {
  id: number;
  inviteUpdateLocalUserDto: InviteUpdateLocalUserDto;
};
export type RecoverAccountApiResponse = /** status 200  */
  | boolean
  | /** status 201  */ object;
export type RecoverAccountApiArg = {
  recoverAccountDto: RecoverAccountDto;
};
export type AuthControllerAuthStatusApiResponse =
  /** status 200  */ LoginStatusDto;
export type AuthControllerAuthStatusApiArg = void;
export type AuthControllerGetAbilitiesApiResponse =
  /** status 200  */ LoginStatusDto;
export type AuthControllerGetAbilitiesApiArg = void;
export type AuthControllerLogoutApiResponse = unknown;
export type AuthControllerLogoutApiArg = void;
export type AuthControllerRefreshTokenApiResponse = /** status 200  */ object;
export type AuthControllerRefreshTokenApiArg = void;
export type SocialGoogleLoginApiResponse = unknown;
export type SocialGoogleLoginApiArg = void;
export type SocialGoogleLoginRedirectApiResponse =
  /** status 200  */ LoginStatusDto;
export type SocialGoogleLoginRedirectApiArg = {
  authuser?: string;
  code?: string;
  prompt?: string;
  scope?: string;
};
export type SocialGoogleIdLoginApiResponse = unknown;
export type SocialGoogleIdLoginApiArg = {
  authid: string;
};
export type SocialGoogleIdLoginRedirectApiResponse =
  /** status 200  */ LoginStatusDto;
export type SocialGoogleIdLoginRedirectApiArg = {
  authuser?: string;
  code?: string;
  prompt?: string;
  scope?: string;
};
export type SocialCasLoginApiResponse = /** status 200  */ LoginStatusDto;
export type SocialCasLoginApiArg = {
  ticket?: string;
  id?: string;
};
export type SocialCasLoginWithIdApiResponse = /** status 200  */ LoginStatusDto;
export type SocialCasLoginWithIdApiArg = {
  authid: string;
  ticket?: string;
  id?: string;
};
export type CreateUserApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type CreateUserApiArg = {
  createUserDto: CreateUserDto;
};
export type FindAllUsersApiResponse = /** status 200  */ User[];
export type FindAllUsersApiArg = void;
export type UpdateUserApiResponse = /** status 200  */ UpdateResult;
export type UpdateUserApiArg = {
  id: number;
  updateUserDto: UpdateUserDto;
};
export type FindOneUserApiResponse = /** status 200  */ User;
export type FindOneUserApiArg = {
  id: number;
};
export type RemoveUserApiResponse = unknown;
export type RemoveUserApiArg = {
  id: number;
};
export type FindAllUsersPaginatedApiResponse =
  /** status 200  */ PaginatedResponseOfUser;
export type FindAllUsersPaginatedApiArg = {
  offset?: number;
  limit?: number;
  filter?: string;
  sort?: string;
};
export type GetUserAuthProvidersApiResponse = /** status 200  */ AuthProvider[];
export type GetUserAuthProvidersApiArg = {
  id: number;
};
export type RemoveAuthProviderApiResponse = /** status 200  */ AuthProvider[];
export type RemoveAuthProviderApiArg = {
  id: number;
  authProviderId: number;
};
export type AddLocalPasswordApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type AddLocalPasswordApiArg = {
  id: number;
  addPasswordDto: AddPasswordDto;
};
export type AddSocialAccountApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type AddSocialAccountApiArg = {
  id: number;
  addSocialAccountDto: AddSocialAccountDto;
};
export type CreateLocalUserApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type CreateLocalUserApiArg = {
  createLocalUserDto: CreateLocalUserDto;
};
export type UpdateMineUserApiResponse = /** status 200  */ UpdateResult;
export type UpdateMineUserApiArg = {
  updateUserDto: UpdateUserDto;
};
export type FindMineUserApiResponse = /** status 200  */ User;
export type FindMineUserApiArg = void;
export type GetMyAuthProvidersApiResponse = /** status 200  */ AuthProvider[];
export type GetMyAuthProvidersApiArg = void;
export type RemoveMyAuthProviderApiResponse = /** status 200  */ AuthProvider[];
export type RemoveMyAuthProviderApiArg = {
  authProviderId: number;
};
export type AddMyLocalPasswordApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type AddMyLocalPasswordApiArg = {
  addPasswordDto: AddPasswordDto;
};
export type AddMySocialAccountApiResponse = /** status 200  */
  | User
  | /** status 201  */ User;
export type AddMySocialAccountApiArg = {
  addSocialAccountDto: AddSocialAccountDto;
};
export type TeamControllerCreateApiResponse = /** status 201  */ Team;
export type TeamControllerCreateApiArg = {
  createTeamDto: CreateTeamDto;
};
export type TeamControllerFindAllApiResponse =
  /** status 200  */ PaginatedResponseOfTeamSummaryForUserDto;
export type TeamControllerFindAllApiArg = {
  offset?: number;
  limit?: number;
};
export type TeamControllerFindOneApiResponse = /** status 200  */ Team;
export type TeamControllerFindOneApiArg = {
  teamId: string;
};
export type TeamControllerUpdateApiResponse = unknown;
export type TeamControllerUpdateApiArg = {
  teamId: string;
  updateTeamDto: UpdateTeamDto;
};
export type TeamControllerRemoveApiResponse = unknown;
export type TeamControllerRemoveApiArg = {
  teamId: string;
};
export type MembershipControllerAddMembersApiResponse = unknown;
export type MembershipControllerAddMembersApiArg = {
  teamId: string;
  createTeamMembershipDto: CreateTeamMembershipDto;
};
export type MembershipControllerGetMembersApiResponse =
  /** status 200  */ Membership[];
export type MembershipControllerGetMembersApiArg = {
  teamId: string;
};
export type MembershipControllerInviteMembersApiResponse = unknown;
export type MembershipControllerInviteMembersApiArg = {
  teamId: string;
  teamInviteMembersDto: TeamInviteMembersDto;
};
export type MembershipControllerGetMemberApiResponse =
  /** status 200  */ Membership;
export type MembershipControllerGetMemberApiArg = {
  teamId: string;
  userId: number;
};
export type MembershipControllerRemoveMemberApiResponse =
  /** status 200  */ Membership;
export type MembershipControllerRemoveMemberApiArg = {
  teamId: string;
  userId: number;
};
export type MembershipControllerUpdateMemberApiResponse =
  /** status 200  */ object;
export type MembershipControllerUpdateMemberApiArg = {
  teamId: string;
  userId: number;
  updateTeamMembershipDto: UpdateTeamMembershipDto;
};
export type TeamRoleControllerCreateRoleApiResponse =
  /** status 201  */ TeamRole;
export type TeamRoleControllerCreateRoleApiArg = {
  teamId: string;
  createTeamRoleDto: CreateTeamRoleDto;
};
export type TeamRoleControllerGetTeamRolesApiResponse =
  /** status 200  */ TeamRole[];
export type TeamRoleControllerGetTeamRolesApiArg = {
  teamId: string;
};
export type TeamRoleControllerUpdateRoleApiResponse = /** status 200  */ object;
export type TeamRoleControllerUpdateRoleApiArg = {
  teamId: string;
  roleId: string;
  updateTeamRoleDto: UpdateTeamRoleDto;
};
export type MyTeamsControllerFindMineApiResponse =
  /** status 200  */ PaginatedResponseOfTeamSummaryForUserDto;
export type MyTeamsControllerFindMineApiArg = {
  offset?: number;
  limit?: number;
};
export type MyTeamsControllerFindOneWithUserMembershipApiResponse =
  /** status 200  */ TeamSummaryForUserDto;
export type MyTeamsControllerFindOneWithUserMembershipApiArg = {
  teamId: string;
};
export type TerrainsControllerCreateApiResponse = /** status 201  */ Terrain;
export type TerrainsControllerCreateApiArg = {
  createTerrainDto: CreateTerrainDto;
};
export type TerrainsControllerFindAllPaginatedApiResponse =
  /** status 200  */ PaginatedResponseOfTerrainSummaryDto;
export type TerrainsControllerFindAllPaginatedApiArg = {
  offset?: number;
  limit?: number;
  teamId?: string;
  filter?: string;
  sort?: string;
};
export type TerrainsControllerUpdateApiResponse = /** status 200  */ Terrain;
export type TerrainsControllerUpdateApiArg = {
  terrainId: number;
  updateTerrainDto: UpdateTerrainDto;
};
export type TerrainsControllerRemoveApiResponse = unknown;
export type TerrainsControllerRemoveApiArg = {
  terrainId: number;
};
export type TerrainsControllerFindOneApiResponse = /** status 200  */ Terrain;
export type TerrainsControllerFindOneApiArg = {
  terrainId: number;
};
export type TerrainsControllerGetTerrainModelLinksApiResponse =
  /** status 200  */ TerrainToTerrainModel[];
export type TerrainsControllerGetTerrainModelLinksApiArg = {
  terrainId: number;
};
export type TerrainsControllerDeleteTerrainModelLinksApiResponse =
  /** status 200  */ DeleteTerrainToTerrainModelsDto;
export type TerrainsControllerDeleteTerrainModelLinksApiArg = {
  terrainId: number;
  deleteTerrainToTerrainModelsDto: DeleteTerrainToTerrainModelsDto;
};
export type TerrainsControllerDeleteTerrainModelLinkApiResponse = unknown;
export type TerrainsControllerDeleteTerrainModelLinkApiArg = {
  terrainId: number;
  terrainToTerrainModelId: number;
};
export type TerrainsControllerUpdateTerrainModelLinkApiResponse =
  /** status 200  */ TerrainToTerrainModel;
export type TerrainsControllerUpdateTerrainModelLinkApiArg = {
  terrainId: number;
  terrainToTerrainModelId: number;
  updateTerrainModelLinkDto: UpdateTerrainModelLinkDto;
};
export type TerrainsControllerCreateTerrainModelLinkApiResponse =
  /** status 201  */ TerrainToTerrainModel;
export type TerrainsControllerCreateTerrainModelLinkApiArg = {
  terrainId: number;
  terrainModelId: number;
};
export type TerrainsControllerGetTerrainLayersApiResponse =
  /** status 200  */ TerrainLayer[];
export type TerrainsControllerGetTerrainLayersApiArg = {
  terrainId: number;
};
export type TerrainsControllerCreateTerrainLayerApiResponse =
  /** status 201  */ TerrainLayer;
export type TerrainsControllerCreateTerrainLayerApiArg = {
  terrainId: number;
  createLayerDto: CreateLayerDto;
};
export type TerrainsControllerCreateTerrainLayerLinkApiResponse = unknown;
export type TerrainsControllerCreateTerrainLayerLinkApiArg = {
  terrainId: number;
  terrainLayerId: number;
};
export type TerrainsControllerDetachLayerApiResponse = unknown;
export type TerrainsControllerDetachLayerApiArg = {
  terrainId: number;
  terrainLayerId: number;
};
export type TerrainsControllerAddUserApiResponse = unknown;
export type TerrainsControllerAddUserApiArg = {
  id: string;
  upsertDocumentMembershipDto: UpsertDocumentMembershipDto;
};
export type TerrainsControllerGetMembershipsApiResponse = unknown;
export type TerrainsControllerGetMembershipsApiArg = {
  id: string;
};
export type TerrainsControllerDeleteMembershipApiResponse = unknown;
export type TerrainsControllerDeleteMembershipApiArg = {
  id: string;
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type TerrainsControllerChangeVisibilityApiResponse =
  /** status 200  */ AccessControlable;
export type TerrainsControllerChangeVisibilityApiArg = {
  id: string;
  updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
};
export type TerrainsControllerChangeTeamApiResponse = unknown;
export type TerrainsControllerChangeTeamApiArg = {
  id: string;
  changeTeamOfObjectDto: ChangeTeamOfObjectDto;
};
export type LayerControllerCreateApiResponse = /** status 201  */ TerrainLayer;
export type LayerControllerCreateApiArg = {
  createLayerDto: CreateLayerDto;
};
export type LayerControllerFindAllApiResponse =
  /** status 200  */ PaginatedResponseOfTerrainLayerWithBuildStatus;
export type LayerControllerFindAllApiArg = {
  offset?: number;
  limit?: number;
  teamId?: string;
  filter?: string;
  sort?: string;
};
export type LayerControllerFindOneApiResponse = /** status 200  */ TerrainLayer;
export type LayerControllerFindOneApiArg = {
  layerId: number;
};
export type LayerControllerUpdateApiResponse = /** status 200  */ TerrainLayer;
export type LayerControllerUpdateApiArg = {
  layerId: number;
  updateLayerDto: UpdateLayerDto;
};
export type LayerControllerRemoveApiResponse = /** status 200  */ TerrainLayer;
export type LayerControllerRemoveApiArg = {
  layerId: number;
};
export type LayerControllerRetrieveBoundsFromSourceApiResponse =
  /** status 200  */ object;
export type LayerControllerRetrieveBoundsFromSourceApiArg = {
  layerId: number;
};
export type LayerControllerAddUserApiResponse = unknown;
export type LayerControllerAddUserApiArg = {
  id: string;
  upsertDocumentMembershipDto: UpsertDocumentMembershipDto;
};
export type LayerControllerGetMembershipsApiResponse = unknown;
export type LayerControllerGetMembershipsApiArg = {
  id: string;
};
export type LayerControllerDeleteMembershipApiResponse = unknown;
export type LayerControllerDeleteMembershipApiArg = {
  id: string;
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type LayerControllerChangeVisibilityApiResponse =
  /** status 200  */ AccessControlable;
export type LayerControllerChangeVisibilityApiArg = {
  id: string;
  updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
};
export type LayerControllerChangeTeamApiResponse = unknown;
export type LayerControllerChangeTeamApiArg = {
  id: string;
  changeTeamOfObjectDto: ChangeTeamOfObjectDto;
};
export type ManagedLayerControllerGetAllApiResponse =
  /** status 200  */ ManagedLayer[];
export type ManagedLayerControllerGetAllApiArg = void;
export type ManagedLayerControllerGetOneApiResponse =
  /** status 200  */ ManagedLayerWithFilesDto;
export type ManagedLayerControllerGetOneApiArg = {
  layerId: number;
};
export type ManagedLayerControllerUpdateApiResponse =
  /** status 200  */ ManagedLayer;
export type ManagedLayerControllerUpdateApiArg = {
  layerId: number;
  updateManagedLayerDto: UpdateManagedLayerDto;
};
export type ManagedLayerControllerDeleteApiResponse =
  /** status 200  */ ManagedLayer;
export type ManagedLayerControllerDeleteApiArg = {
  layerId: number;
};
export type ManagedLayerControllerUploadChunkApiResponse =
  /** status 201  */ object;
export type ManagedLayerControllerUploadChunkApiArg = {
  layerId: number;
  usedFor: string;
  offset: number;
  totalSize: number;
  fileId: string;
  body: {
    file: Blob;
  };
};
export type ManagedLayerControllerDeleteSourceFileApiResponse = unknown;
export type ManagedLayerControllerDeleteSourceFileApiArg = {
  layerId: number;
  fileId: string;
};
export type ManagedLayerControllerDownloadSourceFileApiResponse = unknown;
export type ManagedLayerControllerDownloadSourceFileApiArg = {
  layerId: number;
  fileId: string;
};
export type ManagedLayerControllerClearSourceFilesUsedForApiResponse = unknown;
export type ManagedLayerControllerClearSourceFilesUsedForApiArg = {
  layerId: number;
  usedFor: string;
};
export type ManagedLayerControllerClearTmsFilesApiResponse = unknown;
export type ManagedLayerControllerClearTmsFilesApiArg = {
  layerId: number;
};
export type ManagedLayerControllerBuildApiResponse = /** status 201  */ object;
export type ManagedLayerControllerBuildApiArg = {
  layerId: number;
};
export type ManagedLayerSourcesControllerToucheSourceFilesApiResponse = unknown;
export type ManagedLayerSourcesControllerToucheSourceFilesApiArg = {
  body: TouchSourceFileDto[];
};
export type JobControllerCreateApiResponse = /** status 201  */ Job;
export type JobControllerCreateApiArg = {
  createJobDto: CreateJobDto;
};
export type JobControllerFindAllApiResponse = /** status 200  */ Job[];
export type JobControllerFindAllApiArg = void;
export type JobControllerFindOneApiResponse = /** status 200  */ Job;
export type JobControllerFindOneApiArg = {
  id: string;
};
export type JobControllerUpdateApiResponse = /** status 200  */ Job;
export type JobControllerUpdateApiArg = {
  id: string;
  updateJobDto: UpdateJobDto;
};
export type JobControllerRemoveApiResponse = /** status 200  */ Job;
export type JobControllerRemoveApiArg = {
  id: string;
};
export type StartJobApiResponse = /** status 201  */ JobEventDto;
export type StartJobApiArg = {
  jobId: string;
};
export type StopJobApiResponse = /** status 200  */
  | Job
  | /** status 201  */ object;
export type StopJobApiArg = {
  id: string;
};
export type JobLifeCycleControllerGetJobStatusApiResponse =
  /** status 200  */ JobEventDto;
export type JobLifeCycleControllerGetJobStatusApiArg = {
  jobId: string;
};
export type JobLifeCycleControllerGetJobHistoryApiResponse =
  /** status 200  */ JobEventDto[];
export type JobLifeCycleControllerGetJobHistoryApiArg = {
  jobId: string;
};
export type JobLifeCycleControllerCleanHistoryApiResponse = unknown;
export type JobLifeCycleControllerCleanHistoryApiArg = {
  jobId: string;
};
export type JobLifeCycleControllerGetJobLogsApiResponse =
  /** status 200  */ JobLogDto[];
export type JobLifeCycleControllerGetJobLogsApiArg = {
  jobId: string;
};
export type JobLifeCycleControllerClearLogsApiResponse = unknown;
export type JobLifeCycleControllerClearLogsApiArg = {
  jobId: string;
};
export type AmqpJobControllerLolApiResponse = /** status 200  */ string;
export type AmqpJobControllerLolApiArg = void;
export type DocumentMembershipControllerGetDocumentMembershipsApiResponse =
  /** status 200  */ AccessControlEntry[];
export type DocumentMembershipControllerGetDocumentMembershipsApiArg = {
  documentId?: string;
  documentType?: string;
  teamMembershipId?: string;
};
export type DocumentMembershipControllerRevokeDocumentMembershipApiResponse =
  unknown;
export type DocumentMembershipControllerRevokeDocumentMembershipApiArg = {
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type DocumentRoleControllerGetRolesApiResponse =
  /** status 200  */ AccessControlRole[];
export type DocumentRoleControllerGetRolesApiArg = void;
export type TerrainModelsControllerCreateApiResponse =
  /** status 201  */ TerrainModel;
export type TerrainModelsControllerCreateApiArg = {
  createTerrainModelDto: CreateTerrainModelDto;
};
export type TerrainModelsControllerGetAllApiResponse =
  /** status 200  */ PaginatedResponseOfTerrainModelWithStatus;
export type TerrainModelsControllerGetAllApiArg = {
  offset?: number;
  limit?: number;
  teamId?: string;
  filter?: string;
  sort?: string;
};
export type TerrainModelsControllerRemoveApiResponse =
  /** status 200  */ TerrainModel[];
export type TerrainModelsControllerRemoveApiArg = {
  terrainModelId: number;
};
export type TerrainModelsControllerGetOneApiResponse =
  /** status 200  */ TerrainModel;
export type TerrainModelsControllerGetOneApiArg = {
  terrainModelId: number;
};
export type TerrainModelsControllerUpdateApiResponse =
  /** status 200  */ TerrainModel;
export type TerrainModelsControllerUpdateApiArg = {
  terrainModelId: number;
  updateTerrainModelDto: UpdateTerrainModelDto;
};
export type TerrainModelsControllerAddUserApiResponse = unknown;
export type TerrainModelsControllerAddUserApiArg = {
  id: string;
  upsertDocumentMembershipDto: UpsertDocumentMembershipDto;
};
export type TerrainModelsControllerGetMembershipsApiResponse = unknown;
export type TerrainModelsControllerGetMembershipsApiArg = {
  id: string;
};
export type TerrainModelsControllerDeleteMembershipApiResponse = unknown;
export type TerrainModelsControllerDeleteMembershipApiArg = {
  id: string;
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type TerrainModelsControllerChangeVisibilityApiResponse =
  /** status 200  */ AccessControlable;
export type TerrainModelsControllerChangeVisibilityApiArg = {
  id: string;
  updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
};
export type TerrainModelsControllerChangeTeamApiResponse = unknown;
export type TerrainModelsControllerChangeTeamApiArg = {
  id: string;
  changeTeamOfObjectDto: ChangeTeamOfObjectDto;
};
export type TerrainModelFileControllerCreateRevisionApiResponse =
  /** status 201  */ TerrainModelFileRevision;
export type TerrainModelFileControllerCreateRevisionApiArg = {
  terrainModelId: number;
};
export type TerrainModelFileControllerFindRevsApiResponse =
  /** status 200  */ TerrainModelFileRevision[];
export type TerrainModelFileControllerFindRevsApiArg = {
  terrainModelId: number;
};
export type TerrainModelFileControllerCleanUpRevsApiResponse = unknown;
export type TerrainModelFileControllerCleanUpRevsApiArg = {
  terrainModelId: number;
  /** Keep the current Revision but remove all others */
  keepCurrent?: boolean;
};
export type TerrainModelFileControllerUploadPartApiResponse = unknown;
export type TerrainModelFileControllerUploadPartApiArg = {
  terrainModelId: number;
  revisionId: string;
  partNumber: number;
  body: {
    file: Blob;
  };
};
export type TerrainModelFileControllerCompleteUploadApiResponse = unknown;
export type TerrainModelFileControllerCompleteUploadApiArg = {
  terrainModelId: number;
  revisionId: string;
};
export type TerrainModelFileControllerFindByRevIdApiResponse =
  /** status 200  */ TerrainModelFileRevision;
export type TerrainModelFileControllerFindByRevIdApiArg = {
  terrainModelId: number;
  revisionId: string;
};
export type TerrainModelFileControllerDeleteRevApiResponse = unknown;
export type TerrainModelFileControllerDeleteRevApiArg = {
  terrainModelId: number;
  revisionId: string;
};
export type TerrainModelFileControllerSetCurrentApiResponse = unknown;
export type TerrainModelFileControllerSetCurrentApiArg = {
  terrainModelId: number;
  revisionId: string;
};
export type TerrainModelsCapabilitiesControllerGetCapabilitiesApiResponse =
  /** status 200  */ string[];
export type TerrainModelsCapabilitiesControllerGetCapabilitiesApiArg = void;
export type ActivitiesControllerCreateApiResponse = /** status 201  */ Activity;
export type ActivitiesControllerCreateApiArg = {
  createActivityDto: CreateActivityDto;
};
export type ActivitiesControllerFindAllPaginatedApiResponse =
  /** status 200  */ PaginatedResponseOfActivity;
export type ActivitiesControllerFindAllPaginatedApiArg = {
  offset?: number;
  limit?: number;
  teamId?: string;
  filter?: string;
  sort?: string;
};
export type ActivitiesControllerCreateSlideApiResponse =
  /** status 201  */ Slide;
export type ActivitiesControllerCreateSlideApiArg = {
  activityId: number;
  createSlideDto: CreateSlideDto;
};
export type ActivitiesControllerGetSlidesApiResponse =
  /** status 200  */ Slide[];
export type ActivitiesControllerGetSlidesApiArg = {
  activityId: number;
};
export type ActivitiesControllerUpdateSlidesOrderApiResponse =
  /** status 200  */ Slide[];
export type ActivitiesControllerUpdateSlidesOrderApiArg = {
  activityId: number;
  body: number[];
};
export type ActivitiesControllerFindOneApiResponse =
  /** status 200  */ Activity;
export type ActivitiesControllerFindOneApiArg = {
  activityId: number;
};
export type ActivitiesControllerUpdateApiResponse = unknown;
export type ActivitiesControllerUpdateApiArg = {
  activityId: number;
  updateActivityDto: UpdateActivityDto;
};
export type ActivitiesControllerRemoveApiResponse = /** status 200  */ Activity;
export type ActivitiesControllerRemoveApiArg = {
  activityId: number;
};
export type ActivitiesControllerGetSlidesSummaryApiResponse =
  /** status 200  */ SlideSummaryDto[];
export type ActivitiesControllerGetSlidesSummaryApiArg = {
  activityId: number;
};
export type ActivitiesControllerAddUserApiResponse = unknown;
export type ActivitiesControllerAddUserApiArg = {
  id: string;
  upsertDocumentMembershipDto: UpsertDocumentMembershipDto;
};
export type ActivitiesControllerGetMembershipsApiResponse = unknown;
export type ActivitiesControllerGetMembershipsApiArg = {
  id: string;
};
export type ActivitiesControllerDeleteMembershipApiResponse = unknown;
export type ActivitiesControllerDeleteMembershipApiArg = {
  id: string;
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type ActivitiesControllerChangeVisibilityApiResponse =
  /** status 200  */ AccessControlable;
export type ActivitiesControllerChangeVisibilityApiArg = {
  id: string;
  updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
};
export type ActivitiesControllerChangeTeamApiResponse = unknown;
export type ActivitiesControllerChangeTeamApiArg = {
  id: string;
  changeTeamOfObjectDto: ChangeTeamOfObjectDto;
};
export type GetAllSlidesApiResponse = /** status 200  */ Slide[];
export type GetAllSlidesApiArg = void;
export type GetSlideApiResponse = /** status 200  */ Slide;
export type GetSlideApiArg = {
  slideId: number;
};
export type UpdateSlideApiResponse = /** status 200  */ Slide;
export type UpdateSlideApiArg = {
  slideId: number;
  updateSlideDto: UpdateSlideDto;
};
export type DeleteSlideApiResponse = /** status 200  */ Slide;
export type DeleteSlideApiArg = {
  slideId: number;
};
export type SlidesControllerCopySlideApiResponse = /** status 201  */ Slide;
export type SlidesControllerCopySlideApiArg = {
  slideId: number;
};
export type SlidesControllerLinkTerrainApiResponse = unknown;
export type SlidesControllerLinkTerrainApiArg = {
  slideId: number;
  terrainId: number;
};
export type UnlinkTerrainFromSlideApiResponse = unknown;
export type UnlinkTerrainFromSlideApiArg = {
  slideId: number;
};
export type SlidesControllerAddGeofeatureApiResponse =
  /** status 201  */ object;
export type SlidesControllerAddGeofeatureApiArg = {
  slideId: number;
  createGeoFeatureDto: CreateGeoFeatureDto;
};
export type SlidesControllerClearGeofeatureApiResponse = unknown;
export type SlidesControllerClearGeofeatureApiArg = {
  slideId: number;
};
export type SlidesControllerDeleteGeofeatureApiResponse = unknown;
export type SlidesControllerDeleteGeofeatureApiArg = {
  slideId: number;
  geoFeatureId: string;
};
export type AttachFileToSlideApiResponse = /** status 200  */
  | Slide
  | /** status 201  */ undefined;
export type AttachFileToSlideApiArg = {
  slideId: number;
  fileId: string;
};
export type UploadFileToSlideApiResponse = /** status 200  */
  | Slide
  | /** status 201  */ object;
export type UploadFileToSlideApiArg = {
  slideId: number;
  body: {
    file?: Blob;
  };
};
export type SlidesFilesControllerGetAllMediaApiResponse =
  /** status 200  */ DownloadedFile[];
export type SlidesFilesControllerGetAllMediaApiArg = {
  slideId: number;
};
export type SlidesFilesControllerGetFileStreamApiResponse = unknown;
export type SlidesFilesControllerGetFileStreamApiArg = {
  slideId: number;
  fileId: string;
};
export type DeleteFileFromSlideApiResponse = /** status 200  */ Slide;
export type DeleteFileFromSlideApiArg = {
  slideId: number;
  fileId: string;
};
export type DetachFileFromSlideApiResponse = /** status 200  */ Slide;
export type DetachFileFromSlideApiArg = {
  slideId: number;
  fileId: string;
};
export type SlidesFilesControllerUploadGeoJsonToSlideApiResponse = unknown;
export type SlidesFilesControllerUploadGeoJsonToSlideApiArg = {
  slideId: number;
  body: {
    file?: Blob;
  };
};
export type DownloadedFilesControllerUploadApiResponse =
  /** status 201  */ DownloadedFile;
export type DownloadedFilesControllerUploadApiArg = void;
export type DownloadedFilesControllerFindAllApiResponse =
  /** status 200  */ DownloadedFile[];
export type DownloadedFilesControllerFindAllApiArg = void;
export type DownloadedFilesControllerFindOneApiResponse =
  /** status 200  */ DownloadedFile;
export type DownloadedFilesControllerFindOneApiArg = {
  id: string;
};
export type DownloadedFilesControllerRemoveApiResponse = unknown;
export type DownloadedFilesControllerRemoveApiArg = {
  id: string;
};
export type CourseSessionControllerCreateApiResponse =
  /** status 201  */ CourseSession;
export type CourseSessionControllerCreateApiArg = {
  createCourseSessionDto: CreateCourseSessionDto;
};
export type CourseSessionControllerFindAllApiResponse =
  /** status 200  */ CourseSession[];
export type CourseSessionControllerFindAllApiArg = {
  mine?: boolean;
  enrolled?: boolean;
  teamId?: string;
};
export type CourseSessionControllerFindAllPaginatedApiResponse =
  /** status 200  */ PaginatedResponseOfCourseSession;
export type CourseSessionControllerFindAllPaginatedApiArg = {
  offset?: number;
  limit?: number;
  mine?: boolean;
  enrolled?: boolean;
  teamId?: string;
  filter?: string;
  sort?: string;
};
export type CourseSessionControllerFindOneApiResponse =
  /** status 200  */ CourseSession;
export type CourseSessionControllerFindOneApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerUpdateApiResponse =
  /** status 200  */ CourseSession;
export type CourseSessionControllerUpdateApiArg = {
  courseSessionId: number;
  updateCourseSessionDto: UpdateCourseSessionDto;
};
export type CourseSessionControllerRemoveApiResponse =
  /** status 200  */ CourseSession;
export type CourseSessionControllerRemoveApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerGetMyRoleApiResponse =
  /** status 200  */ CourseSessionRoleDto;
export type CourseSessionControllerGetMyRoleApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerGetActivitiesApiResponse =
  /** status 200  */ Activity[];
export type CourseSessionControllerGetActivitiesApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerCreateAndConnectedActivityApiResponse =
  /** status 201  */ Activity;
export type CourseSessionControllerCreateAndConnectedActivityApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerUpdateActivitiesApiResponse =
  /** status 200  */ ActivityProgress[];
export type CourseSessionControllerUpdateActivitiesApiArg = {
  courseSessionId: number;
  updateCourseSessionActivitiesDto: UpdateCourseSessionActivitiesDto;
};
export type CourseSessionControllerConnectActivityApiResponse =
  /** status 201  */ ActivityProgress;
export type CourseSessionControllerConnectActivityApiArg = {
  courseSessionId: number;
  activityId: number;
};
export type CourseSessionControllerSetCurrentModeApiResponse =
  /** status 201  */ CourseGroupLayout;
export type CourseSessionControllerSetCurrentModeApiArg = {
  courseSessionId: number;
  updateCurrentModeDto: UpdateCurrentModeDto;
};
export type CourseSessionControllerDisconnectActivityApiResponse =
  /** status 200  */ ActivityProgress[];
export type CourseSessionControllerDisconnectActivityApiArg = {
  courseSessionId: number;
  activityProgressId: number;
};
export type CourseSessionControllerGetOneActivityProgressApiResponse =
  /** status 200  */ ActivityProgress;
export type CourseSessionControllerGetOneActivityProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
};
export type CourseSessionControllerGetAllActivityProgressApiResponse =
  /** status 200  */ ActivityProgress[];
export type CourseSessionControllerGetAllActivityProgressApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerGetSlidesSummaryForActivityProgressApiResponse =
  /** status 200  */ SlideSummaryDto[];
export type CourseSessionControllerGetSlidesSummaryForActivityProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
};
export type CourseSessionControllerGetAllGroupsForCourseSessionApiResponse =
  /** status 200  */ CourseGroup[];
export type CourseSessionControllerGetAllGroupsForCourseSessionApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerGetAllSlideProgressRecordsForActivityProgressApiResponse =
  /** status 200  */ SlideProgress[];
export type CourseSessionControllerGetAllSlideProgressRecordsForActivityProgressApiArg =
  {
    courseSessionId: number;
    activityProgressId: number;
  };
export type CourseSessionControllerGetCurrentGroupForUserApiResponse =
  /** status 200  */ CourseGroup;
export type CourseSessionControllerGetCurrentGroupForUserApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerGetCurrentStateForGroupApiResponse =
  /** status 200  */ CurrentGroupStateDto;
export type CourseSessionControllerGetCurrentStateForGroupApiArg = {
  courseSessionId: number;
  groupId: number;
};
export type CourseSessionControllerSetCurrentStateForGroupApiResponse =
  /** status 201  */ CurrentGroupStateDto;
export type CourseSessionControllerSetCurrentStateForGroupApiArg = {
  courseSessionId: number;
  groupId: number;
};
export type CourseSessionControllerGetAllSlideProgressRecordsForSlideApiResponse =
  /** status 200  */ SlideProgress[];
export type CourseSessionControllerGetAllSlideProgressRecordsForSlideApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
};
export type CourseSessionControllerGetSlideProgressForGroupApiResponse =
  /** status 200  */ SlideProgress;
export type CourseSessionControllerGetSlideProgressForGroupApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
  groupId: number;
};
export type CourseSessionControllerAddFileToSlideProgressApiResponse = unknown;
export type CourseSessionControllerAddFileToSlideProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
  groupId: number;
  fileId: string;
};
export type CourseSessionControllerDetachFileFromSlideProgressApiResponse =
  /** status 200  */ SlideProgress;
export type CourseSessionControllerDetachFileFromSlideProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
  groupId: number;
  fileId: string;
};
export type CourseSessionControllerUploadFileToSlideProgressApiResponse =
  unknown;
export type CourseSessionControllerUploadFileToSlideProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
  groupId: number;
};
export type CourseSessionControllerGetFilesOfSlideProgressApiResponse =
  /** status 200  */ DownloadedFile[];
export type CourseSessionControllerGetFilesOfSlideProgressApiArg = {
  courseSessionId: number;
  activityProgressId: number;
  slideId: number;
  groupId: number;
};
export type CourseSessionControllerResetSessionApiResponse =
  /** status 200  */ SlideProgress[];
export type CourseSessionControllerResetSessionApiArg = {
  courseSessionId: number;
};
export type CourseSessionControllerSetOwnerApiResponse = unknown;
export type CourseSessionControllerSetOwnerApiArg = {
  courseSessionId: number;
  userId: number;
};
export type CourseSessionControllerAddUserApiResponse = unknown;
export type CourseSessionControllerAddUserApiArg = {
  id: string;
  upsertDocumentMembershipDto: UpsertDocumentMembershipDto;
};
export type CourseSessionControllerGetMembershipsApiResponse = unknown;
export type CourseSessionControllerGetMembershipsApiArg = {
  id: string;
};
export type CourseSessionControllerDeleteMembershipApiResponse = unknown;
export type CourseSessionControllerDeleteMembershipApiArg = {
  id: string;
  findOneDocumentMembershipDto: FindOneDocumentMembershipDto;
};
export type CourseSessionControllerChangeVisibilityApiResponse =
  /** status 200  */ AccessControlable;
export type CourseSessionControllerChangeVisibilityApiArg = {
  id: string;
  updateDocumentVisibilityDto: UpdateDocumentVisibilityDto;
};
export type CourseSessionControllerChangeTeamApiResponse = unknown;
export type CourseSessionControllerChangeTeamApiArg = {
  id: string;
  changeTeamOfObjectDto: ChangeTeamOfObjectDto;
};
export type CourseSessionStatsControllerGetActivityStatsApiResponse =
  /** status 200  */ ActivityStatsDto;
export type CourseSessionStatsControllerGetActivityStatsApiArg = {
  courseSessionId: number;
  activityProgressId: number;
};
export type CourseSessionStatsControllerGetActivityProgressSummariesApiResponse =
  /** status 200  */ ActivityProgressByGroupLayoutsDto[];
export type CourseSessionStatsControllerGetActivityProgressSummariesApiArg = {
  courseSessionId: number;
};
export type CourseSessionStatsControllerGetCurrentGroupProgressApiResponse =
  /** status 200  */ CurrentGroupProgressDto;
export type CourseSessionStatsControllerGetCurrentGroupProgressApiArg = {
  courseSessionId: number;
  groupId: number;
};
export type CourseSessionStatsControllerGetAllGroupLayoutProgressApiResponse =
  /** status 200  */ GroupLayoutProgressDto[];
export type CourseSessionStatsControllerGetAllGroupLayoutProgressApiArg = {
  courseSessionId: number;
};
export type CourseSessionStatsControllerGetOneGroupLayoutProgressApiResponse =
  /** status 200  */ GroupLayoutProgressDto;
export type CourseSessionStatsControllerGetOneGroupLayoutProgressApiArg = {
  courseSessionId: number;
  groupLayoutId: number;
};
export type CourseSessionStatsControllerGetCurrentGroupLayoutProgressApiResponse =
  /** status 200  */ GroupLayoutProgressDto;
export type CourseSessionStatsControllerGetCurrentGroupLayoutProgressApiArg = {
  courseSessionId: number;
};
export type CourseSessionStudentsControllerAddStudentApiResponse = unknown;
export type CourseSessionStudentsControllerAddStudentApiArg = {
  courseSessionId: number;
  studentId: number;
};
export type CourseSessionStudentsControllerRemoveStudentApiResponse = unknown;
export type CourseSessionStudentsControllerRemoveStudentApiArg = {
  courseSessionId: number;
  studentId: number;
};
export type CourseSessionStudentsControllerGetAllStudentsApiResponse =
  /** status 200  */ User[];
export type CourseSessionStudentsControllerGetAllStudentsApiArg = {
  courseSessionId: number;
};
export type CreateCourseGroupApiResponse = /** status 200  */
  | CourseGroup
  | /** status 201  */ CourseGroup;
export type CreateCourseGroupApiArg = {
  createCourseGroupDto: CreateCourseGroupDto;
};
export type GetAllCourseGroupsApiResponse = /** status 200  */ CourseGroup[];
export type GetAllCourseGroupsApiArg = void;
export type GetCourseGroupApiResponse = /** status 200  */ CourseGroup;
export type GetCourseGroupApiArg = {
  courseGroupId: number;
};
export type UpdateCourseGroupApiResponse = /** status 200  */ CourseGroup;
export type UpdateCourseGroupApiArg = {
  courseGroupId: number;
  createCourseGroupDto: CreateCourseGroupDto;
};
export type DeleteCourseGroupApiResponse = /** status 200  */ CourseGroup;
export type DeleteCourseGroupApiArg = {
  courseGroupId: number;
};
export type SlideProgressControllerFindOneApiResponse =
  /** status 200  */ SlideProgress;
export type SlideProgressControllerFindOneApiArg = {
  slideProgressId: number;
};
export type SlideProgressControllerUpdateApiResponse =
  /** status 200  */ SlideProgress;
export type SlideProgressControllerUpdateApiArg = {
  slideProgressId: number;
  updateSlideProgressDto: UpdateSlideProgressDto;
};
export type SlideProgressControllerAttachFileApiResponse = unknown;
export type SlideProgressControllerAttachFileApiArg = {
  slideProgressId: number;
  fileId: string;
};
export type SlideProgressControllerGetFileDataApiResponse = unknown;
export type SlideProgressControllerGetFileDataApiArg = {
  slideProgressId: number;
  fileId: string;
};
export type SlideProgressControllerDetachFileApiResponse =
  /** status 200  */ SlideProgress;
export type SlideProgressControllerDetachFileApiArg = {
  slideProgressId: number;
  fileId: string;
};
export type SlideProgressControllerUploadFileApiResponse = unknown;
export type SlideProgressControllerUploadFileApiArg = {
  slideProgressId: number;
  body: {
    file?: Blob;
  };
};
export type SlideProgressControllerDeleteFileApiResponse = unknown;
export type SlideProgressControllerDeleteFileApiArg = {
  slideProgressId: number;
  fileId: string;
};
export type SlideProgressControllerGetFilesApiResponse =
  /** status 200  */ DownloadedFile[];
export type SlideProgressControllerGetFilesApiArg = {
  slideProgressId: number;
};
export type SlideProgressControllerCreateGeoFeatureApiResponse =
  /** status 201  */ SlideProgress;
export type SlideProgressControllerCreateGeoFeatureApiArg = {
  slideProgressId: number;
  createGeoFeatureDto: CreateGeoFeatureDto;
};
export type SlideProgressControllerGetAllGeoFeaturesApiResponse =
  /** status 200  */ GeoFeature[];
export type SlideProgressControllerGetAllGeoFeaturesApiArg = {
  slideProgressId: number;
};
export type SlideProgressControllerGetAllSlideGeoFeaturesApiResponse =
  /** status 200  */ GeoFeature[];
export type SlideProgressControllerGetAllSlideGeoFeaturesApiArg = {
  slideProgressId: number;
};
export type SlideProgressControllerDeleteGeoFeatureApiResponse =
  /** status 200  */ SlideProgress;
export type SlideProgressControllerDeleteGeoFeatureApiArg = {
  slideProgressId: number;
  geoFeatureId: string;
};
export type ActivityProgressControllerCreateApiResponse =
  /** status 201  */ ActivityProgress;
export type ActivityProgressControllerCreateApiArg = {
  createActivityProgressDto: CreateActivityProgressDto;
};
export type GetAllActivityProgressApiResponse =
  /** status 200  */ ActivityProgress[];
export type GetAllActivityProgressApiArg = void;
export type GetActivityProgressApiResponse =
  /** status 200  */ ActivityProgress;
export type GetActivityProgressApiArg = {
  id: number;
};
export type UpdateActivityProgressApiResponse =
  /** status 200  */ ActivityProgress;
export type UpdateActivityProgressApiArg = {
  id: number;
  updateActivityProgressDto: UpdateActivityProgressDto;
};
export type DeleteActivityProgressApiResponse =
  /** status 200  */ ActivityProgress;
export type DeleteActivityProgressApiArg = {
  id: number;
};
export type ActivityProgressControllerGetActivityProgressSlidesApiResponse =
  /** status 200  */ SlideProgress[];
export type ActivityProgressControllerGetActivityProgressSlidesApiArg = {
  activityProgressId: number;
};
export type ActivityProgressControllerCreateSlideProgressApiResponse =
  /** status 201  */ SlideProgress;
export type ActivityProgressControllerCreateSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
  createSlideProgressDto: CreateSlideProgressDto;
};
export type ActivityProgressControllerGetSlideProgressApiResponse =
  /** status 200  */ SlideProgress;
export type ActivityProgressControllerGetSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
};
export type UpdateSlideProgressApiResponse = /** status 200  */ SlideProgress[];
export type UpdateSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
  createSlideProgressDto: CreateSlideProgressDto;
};
export type ActivityProgressControllerAddFileToSlideProgressApiResponse =
  /** status 201  */ SlideProgress;
export type ActivityProgressControllerAddFileToSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
  fileId: string;
};
export type ActivityProgressControllerRemoveFileFromSlideProgressApiResponse =
  /** status 200  */ SlideProgress;
export type ActivityProgressControllerRemoveFileFromSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
  fileId: string;
};
export type ActivityProgressControllerUploadFileToSlideProgressApiResponse =
  /** status 201  */ SlideProgress;
export type ActivityProgressControllerUploadFileToSlideProgressApiArg = {
  id: number;
  slideId: number;
  groupId: number;
};
export type PostAnswerApiResponse = unknown;
export type PostAnswerApiArg = {
  questionId: number;
};
export type UpdateAnswerApiResponse = unknown;
export type UpdateAnswerApiArg = {
  questionId: number;
};
export type GetAnswerApiResponse = unknown;
export type GetAnswerApiArg = {
  questionId: number;
  groupId: number;
};
export type ArtifactsControllerGetPackagesApiResponse =
  /** status 200  */ PackageDto[];
export type ArtifactsControllerGetPackagesApiArg = void;
export type ArtifactsControllerGetVersionsApiResponse =
  /** status 200  */ PackageVersionSummaryDto[];
export type ArtifactsControllerGetVersionsApiArg = {
  packageName: string;
  namespace: string;
};
export type ArtifactsControllerCreateReleaseChannelApiResponse =
  /** status 201  */ ArtifactRelease;
export type ArtifactsControllerCreateReleaseChannelApiArg = {
  createArtifactReleaseDto: CreateArtifactReleaseDto;
};
export type ArtifactsControllerGetReleaseChannelsPaginatedApiResponse =
  /** status 200  */ PaginatedResponseOfArtifactRelease;
export type ArtifactsControllerGetReleaseChannelsPaginatedApiArg = {
  offset?: number;
  limit?: number;
};
export type ArtifactsControllerGetReleaseChannelApiResponse =
  /** status 200  */ ArtifactRelease;
export type ArtifactsControllerGetReleaseChannelApiArg = {
  releaseId: string;
};
export type ArtifactsControllerUpdateReleaseApiResponse = unknown;
export type ArtifactsControllerUpdateReleaseApiArg = {
  releaseId: string;
  updateArtifactReleaseDto: UpdateArtifactReleaseDto;
};
export type ArtifactsControllerDeleteReleaseApiResponse = unknown;
export type ArtifactsControllerDeleteReleaseApiArg = {
  releaseId: string;
};
export type ArtifactsControllerGetReleaseRssByNameAndChannelApiResponse =
  /** status 200  */ string;
export type ArtifactsControllerGetReleaseRssByNameAndChannelApiArg = {
  name: string;
  channel: string;
};
export type ArtifactsControllerGetArtifactRssApiResponse =
  /** status 200  */ string;
export type ArtifactsControllerGetArtifactRssApiArg = {
  releaseId: string;
};
export type ArtifactsControllerGetReleaseAssetFileApiResponse = unknown;
export type ArtifactsControllerGetReleaseAssetFileApiArg = {
  releaseId: string;
  artifactName: string;
};
export type TeamStorageControllerGetStorageStatsApiResponse =
  /** status 200  */ TeamStorageDto;
export type TeamStorageControllerGetStorageStatsApiArg = {
  teamId: string;
};
export type TerrainModelConfDto = {
  allowedTypes: (
    | "FILE_IN_SHARED_ASSETS_FOLDER"
    | "FILE_AS_REMOTE_ASSET_BUNDLE"
    | "TILES_FROM_EXTERNAL_SOURCE"
    | "TILES_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_INTERNAL_SOURCE"
    | "TILESV3_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_EXTERNAL_SOURCE"
    | "POINTCLOUD_FROM_INTERNAL_SOURCE"
    | "GLB_FROM_EXTERNAL_SOURCE"
    | "GLB_FROM_INTERNAL_SOURCE"
  )[];
};
export type VersionInfo = {
  version: string;
  appName: string;
  supportEmail: string;
  photonRegion: string;
  noQuotas: boolean;
  sentryEnabled: boolean;
  sentryFrontendDSN: string;
  models3dConf: TerrainModelConfDto;
};
export type ClientAppStatus = {
  isCompatible: boolean;
  showMessage: boolean;
  message: string;
};
export type ClientAppStatusDto = {
  version: string;
  applicationName: string;
  isVrApplication: boolean;
  debug: boolean;
};
export type AuthMethodsDto = {
  useCas: boolean;
  useGoogleOauth: boolean;
  useLocal: boolean;
};
export type LoginUserDto = {
  username: string;
  password: string;
};
export type UserPreferencesProps = {
  displayGrid: boolean;
  displayGround: boolean;
};
export type UserPreferences = {
  vrExplorer2D: UserPreferencesProps;
  vrExplorerVR: UserPreferencesProps;
};
export type AuthProvider = {
  id: number;
  providerKey: string;
  providerType: string;
  user: User;
};
export type User = {
  createdAt: string;
  updatedAt: string;
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  preferences: UserPreferences;
  role: "STANDARD" | "ADMIN";
  authProviders: AuthProvider[];
  lastActivityDate?: string;
};
export type LoginStatusDto = {
  status: string;
  user?: User;
  rules?: object;
};
export type ResetPasswordLinkIsValid = {
  isValid: boolean;
  email: string;
};
export type ValidInvitationDto = {
  hash: string;
  timestamp: number;
};
export type ResetLocalPasswordDto = {
  hash?: string;
  timestamp?: number;
  password: string;
};
export type InviteUpdateLocalUserDto = {
  hash?: string;
  timestamp?: number;
  firstName?: string;
  lastName?: string;
  password: string;
};
export type RecoverAccountDto = {
  email: string;
};
export type CreateUserDto = {
  username: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  preferences?: UserPreferences;
  role: "STANDARD" | "ADMIN";
};
export type UpdateResult = {};
export type UpdateUserDto = {
  username?: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  preferences?: UserPreferences;
  role?: "STANDARD" | "ADMIN";
};
export type PaginatedResponseOfUser = {
  total: number;
  limit: number;
  offset: number;
  results: User[];
};
export type AddPasswordDto = {
  password: string;
};
export type AddSocialAccountDto = {
  providerType: string;
  providerKey: string;
};
export type CreateLocalUserDto = {
  username?: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  preferences?: UserPreferences;
  role?: "STANDARD" | "ADMIN";
  password: string;
};
export type TeamPermission = {
  action: string;
};
export type TeamRole = {
  id: string;
  name: string;
  description?: string;
  permissions: TeamPermission[];
};
export type Membership = {
  id: string;
  team: Team;
  teamId: string;
  user: User;
  userId: number;
  role: TeamRole;
};
export type Team = {
  id: string;
  name: string;
  members: Membership[];
  visibility: "PRIVATE" | "PUBLIC";
  adminCountLimit: number;
  guestCountLimitByAdmin: number;
  storageCapacityLimitByAdmin: number;
};
export type CreateTeamDto = {
  name: string;
  adminCountLimit?: number;
  guestCountLimitByAdmin?: number;
  storageCapacityLimitByAdmin?: number;
};
export type TeamMembershipDto = {
  role: TeamRole;
  membershipId: string;
  userId: number;
};
export type TeamSummaryForUserDto = {
  userMembership?: TeamMembershipDto | null;
  teamId: string;
  name: string;
  numUsers: number;
  visibility: "PRIVATE" | "PUBLIC";
  adminCountLimit: number;
  guestCountLimitByAdmin: number;
  storageCapacityLimitByAdmin: number;
};
export type PaginatedResponseOfTeamSummaryForUserDto = {
  total: number;
  limit: number;
  offset: number;
  results: TeamSummaryForUserDto[];
};
export type UpdateTeamDto = {
  visibility?: ("PRIVATE" | "PUBLIC") | null;
  name?: string;
  adminCountLimit?: number;
  guestCountLimitByAdmin?: number;
  storageCapacityLimitByAdmin?: number;
};
export type CreateTeamMembershipDto = {
  teamId: string;
  userId: number;
  role: TeamRole;
};
export type TeamInviteMembersLineDto = {
  email: string;
  role: TeamRole;
};
export type TeamInviteMembersDto = {
  inviteMembersLines: TeamInviteMembersLineDto[];
};
export type UpdateTeamMembershipDto = {
  role: TeamRole;
};
export type CreateTeamPermissionDto = {
  action: string;
};
export type CreateTeamRoleDto = {
  name: string;
  description?: string;
  permissions: CreateTeamPermissionDto[];
};
export type UpdateTeamRoleDto = {
  name?: string;
  description?: string;
  permissions?: CreateTeamPermissionDto[];
};
export type Vector3 = {
  x: number;
  y: number;
  z: number;
};
export type AccessControlPermission = {
  action: string;
};
export type AccessControlRole = {
  id: string;
  name: string;
  permissions: AccessControlPermission[];
};
export type AccessControlEntry = {
  objId: string;
  objType: string;
  membershipId: string;
  membership?: Membership;
  role: AccessControlRole;
  uuid: string;
};
export type TerrainModel = {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  meshFilename: string;
  meshPosition: Vector3;
  meshRotation: Vector3;
  meshScale: Vector3;
  size: string;
  meshTransformMatrix: number[][];
  meshDataSourceType:
    | "FILE_IN_SHARED_ASSETS_FOLDER"
    | "FILE_AS_REMOTE_ASSET_BUNDLE"
    | "TILES_FROM_EXTERNAL_SOURCE"
    | "TILES_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_INTERNAL_SOURCE"
    | "TILESV3_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_EXTERNAL_SOURCE"
    | "POINTCLOUD_FROM_INTERNAL_SOURCE"
    | "GLB_FROM_EXTERNAL_SOURCE"
    | "GLB_FROM_INTERNAL_SOURCE";
  terrainToTerrainModels: TerrainToTerrainModel[];
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type TerrainToTerrainModel = {
  id: number;
  visible: boolean;
  overrideGeoRef: boolean;
  meshPosition: Vector3;
  meshRotation: Vector3;
  meshScale: Vector3;
  terrain: Terrain;
  terrainModel: TerrainModel;
};
export type TerrainLayer = {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  url: string;
  sourceType: "VIRTUAFIELD";
  dataType: "MIXED" | "DEM" | "TEXTURE";
  managementMode: "EXTERNAL" | "INTERNAL";
  connectionParams: object[];
  terrains: Terrain[];
  size: string;
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type TerrainBoundsDto = {
  pMin: Vector3;
  pMax: Vector3;
};
export type Terrain = {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  description: string;
  terrainToTerrainModels: TerrainToTerrainModel[];
  terrainLayers: TerrainLayer[];
  bounds: TerrainBoundsDto;
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type CreateTerrainDto = {
  name: string;
  description?: string;
  teamId: string;
};
export type TeamShortDto = {
  teamId: string;
  name: string;
};
export type TerrainSummaryDto = {
  id: number;
  name: string;
  team: TeamShortDto;
  createdAt: string;
  updatedAt: string;
  teamId: string;
  visibility: string;
  perms: AccessControlEntry[];
};
export type PaginatedResponseOfTerrainSummaryDto = {
  total: number;
  limit: number;
  offset: number;
  results: TerrainSummaryDto[];
};
export type UpdateTerrainDto = {
  name?: string;
  description?: string;
};
export type DeleteTerrainToTerrainModelsDto = {
  ids: number[];
};
export type UpdateTerrainModelLinkDto = {
  visible?: boolean;
  overrideGeoRef?: boolean;
  meshPosition?: Vector3;
  meshRotation?: Vector3;
  meshScale?: Vector3;
};
export type CreateLayerDto = {
  name: string;
  url?: string;
  connectionParams?: object;
  sourceType?: "VIRTUAFIELD";
  dataType?: "MIXED" | "DEM" | "TEXTURE";
  managementMode?: "EXTERNAL" | "INTERNAL";
  teamId: string;
};
export type UpsertDocumentMembershipDto = {
  documentRoleId: string;
  documentId: string;
  documentType: string;
  teamMembershipId: string;
};
export type FindOneDocumentMembershipDto = {
  documentId: string;
  documentType: string;
  teamMembershipId: string;
};
export type AccessControlable = {
  teamId: string;
  visibility: string;
  perms: AccessControlEntry[];
};
export type UpdateDocumentVisibilityDto = {
  visibility: "Private" | "Team" | "Public";
};
export type ChangeTeamOfObjectDto = {
  ifAclEntries: "clear_acl_entries" | "block_team_mod";
  teamId: string;
};
export type TerrainLayerWithBuildStatus = {
  createdAt: string;
  updatedAt: string;
  status?: string;
  id: number;
  name: string;
  url: string;
  sourceType: "VIRTUAFIELD";
  dataType: "MIXED" | "DEM" | "TEXTURE";
  managementMode: "EXTERNAL" | "INTERNAL";
  connectionParams: object[];
  terrains: Terrain[];
  size: string;
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type PaginatedResponseOfTerrainLayerWithBuildStatus = {
  total: number;
  limit: number;
  offset: number;
  results: TerrainLayerWithBuildStatus[];
};
export type DownloadedFile = {
  id: string;
  name: string;
  mimeType: string;
  url: string;
  hash: string;
};
export type UpdateLayerDto = {
  demFiles?: DownloadedFile[];
  textureFiles?: DownloadedFile[];
  connectionParams?: object;
  name?: string;
  url?: string;
  sourceType?: "VIRTUAFIELD";
  dataType?: "MIXED" | "DEM" | "TEXTURE";
  managementMode?: "EXTERNAL" | "INTERNAL";
};
export type ManagedLayer = {
  name: string;
  demTileSize: number;
  texTileSize: number;
  status: "dirty" | "up_to_date";
  jobId: string;
  layer: TerrainLayer;
  layerId: number;
  autoBuildAfterUpload: boolean;
};
export type SourceFile = {
  createdAt: string;
  updatedAt: string;
  layer: ManagedLayer;
  layerId: number;
  layerFileUsage: "dem" | "tex";
  id: string;
  name: string;
  path: string;
  lastUploadedOffset: number;
  totalSize: number;
  isOnline: boolean;
  hash: string;
};
export type ManagedLayerWithFilesDto = {
  demSourceFiles: SourceFile[];
  texSourceFiles: SourceFile[];
  name?: string;
  demTileSize?: number;
  texTileSize?: number;
  status?: "dirty" | "up_to_date";
  jobId?: string;
  layer?: TerrainLayer;
  layerId?: number;
  autoBuildAfterUpload?: boolean;
};
export type UpdateManagedLayerDto = {
  demTileSize?: number;
  texTileSize?: number;
  autoBuildAfterUpload?: boolean;
};
export type TouchSourceFileDto = {
  id: string;
  layerId: number;
  name: string;
  totalSize: number;
  layerFileUsage: "dem" | "tex";
};
export type Job = {
  id: string;
  args: object;
  status: "NOT_SUBMITED" | "PENDING" | "RUNNING" | "DONE" | "ERROR";
  logs: string;
};
export type CreateJobDto = {
  id: string;
  demFilename: string;
  textureFilename: string;
  outDir: string;
  demTileSize: number;
  textureTileSize: number;
};
export type UpdateJobDto = {
  sourceFiles?: DownloadedFile[];
  id?: string;
  demFilename?: string;
  textureFilename?: string;
  outDir?: string;
  demTileSize?: number;
  textureTileSize?: number;
};
export type JobEventDto = {
  timestamp: string;
  status: "NOT_SUBMITED" | "PENDING" | "RUNNING" | "DONE" | "ERROR";
};
export type JobLogDto = {
  timestamp: string;
  log: string;
};
export type CreateTerrainModelDto = {
  name: string;
  meshDataSourceType:
    | "FILE_IN_SHARED_ASSETS_FOLDER"
    | "FILE_AS_REMOTE_ASSET_BUNDLE"
    | "TILES_FROM_EXTERNAL_SOURCE"
    | "TILES_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_INTERNAL_SOURCE"
    | "TILESV3_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_EXTERNAL_SOURCE"
    | "POINTCLOUD_FROM_INTERNAL_SOURCE"
    | "GLB_FROM_EXTERNAL_SOURCE"
    | "GLB_FROM_INTERNAL_SOURCE";
  teamId: string;
};
export type TerrainModelWithStatus = {
  createdAt: string;
  updatedAt: string;
  progress?: string;
  id: number;
  name: string;
  meshFilename: string;
  meshPosition: Vector3;
  meshRotation: Vector3;
  meshScale: Vector3;
  size: string;
  meshTransformMatrix: number[][];
  meshDataSourceType:
    | "FILE_IN_SHARED_ASSETS_FOLDER"
    | "FILE_AS_REMOTE_ASSET_BUNDLE"
    | "TILES_FROM_EXTERNAL_SOURCE"
    | "TILES_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_INTERNAL_SOURCE"
    | "TILESV3_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_EXTERNAL_SOURCE"
    | "POINTCLOUD_FROM_INTERNAL_SOURCE"
    | "GLB_FROM_EXTERNAL_SOURCE"
    | "GLB_FROM_INTERNAL_SOURCE";
  terrainToTerrainModels: TerrainToTerrainModel[];
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type PaginatedResponseOfTerrainModelWithStatus = {
  total: number;
  limit: number;
  offset: number;
  results: TerrainModelWithStatus[];
};
export type UpdateTerrainModelDto = {
  name?: string;
  meshFilename?: string;
  meshPosition?: Vector3;
  meshRotation?: Vector3;
  meshScale?: Vector3;
  meshTransformMatrix: number[][];
  meshDataSourceType?:
    | "FILE_IN_SHARED_ASSETS_FOLDER"
    | "FILE_AS_REMOTE_ASSET_BUNDLE"
    | "TILES_FROM_EXTERNAL_SOURCE"
    | "TILES_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_INTERNAL_SOURCE"
    | "TILESV3_FROM_INTERNAL_SOURCE"
    | "TILESV2_FROM_EXTERNAL_SOURCE"
    | "POINTCLOUD_FROM_INTERNAL_SOURCE"
    | "GLB_FROM_EXTERNAL_SOURCE"
    | "GLB_FROM_INTERNAL_SOURCE";
};
export type TerrainModelFileRevision = {
  createdAt: string;
  updatedAt: string;
  id: string;
  terrainModel: TerrainModel;
  uploadId: string;
  originalFilename: string;
  isCurrent: boolean;
  status: "dirty" | "up_to_date";
  progress: "uploading" | "post_processing" | "done" | "error";
};
export type PossibleAnswer = {
  answer: string;
  mark: number;
};
export type QuizContent = {
  description: string;
  possibleAnswers: PossibleAnswer[];
};
export type GeoFeature = {
  id: string;
  content: string;
};
export type Slide = {
  id: number;
  title: string;
  startupPositionMode:
    | "DEFAULT"
    | "CUSTOM"
    | "LATEST_OR_DEFAULT"
    | "LATEST_OR_CUSTOM";
  mapPosition?: Vector3;
  mapRotation?: Vector3;
  mapScale?: number;
  content: string;
  quiz: QuizContent;
  quizType:
    | "NONE"
    | "FREEFORM"
    | "SINGLE_CHOICE"
    | "MULTIPLE_CHOICE"
    | "REORDER_CHOICE";
  order: number;
  activity: Activity;
  terrain?: Terrain;
  files: DownloadedFile[];
  geoFeatures: GeoFeature[];
};
export type Activity = {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  description: string;
  slides: Slide[];
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type CreateActivityDto = {
  description?: string;
  name: string;
  teamId: string;
};
export type PaginatedResponseOfActivity = {
  total: number;
  limit: number;
  offset: number;
  results: Activity[];
};
export type CreateSlideDto = {
  title?: string;
  order?: number;
};
export type UpdateActivityDto = {
  description?: string;
  name?: string;
};
export type SlideSummaryDto = {
  id: number;
  title?: string;
  quizType?:
    | "NONE"
    | "FREEFORM"
    | "SINGLE_CHOICE"
    | "MULTIPLE_CHOICE"
    | "REORDER_CHOICE";
};
export type UpdateSlideDto = {
  files?: DownloadedFile[];
  content?: string;
  quizType?:
    | "NONE"
    | "FREEFORM"
    | "SINGLE_CHOICE"
    | "MULTIPLE_CHOICE"
    | "REORDER_CHOICE";
  quiz?: QuizContent;
  mapPosition?: Vector3;
  mapRotation?: Vector3;
  mapScale?: number;
  startupPositionMode?:
    | "DEFAULT"
    | "CUSTOM"
    | "LATEST_OR_DEFAULT"
    | "LATEST_OR_CUSTOM";
  title?: string;
  order?: number;
};
export type CreateGeoFeatureDto = {
  id: string;
  content: string;
};
export type CourseGroup = {
  id: number;
  name: string;
  users: User[];
};
export type CourseGroupLayout = {
  id: number;
  name: string;
  groups: CourseGroup[];
};
export type SlideProgress = {
  id: number;
  slide: Slide;
  slideId: number;
  activityProgress: ActivityProgress;
  activityProgressId: number;
  visited: boolean;
  group: CourseGroup;
  groupId: number;
  quizAnswers: string[];
  dataProduced: DownloadedFile[];
  hash: string;
  geoFeatures: GeoFeature[];
};
export type ActivityProgress = {
  id: number;
  activity: Activity;
  slideProgressRecords: SlideProgress[];
  lockedForGrading: boolean;
  activityId: number;
  courseSession: CourseSession;
  courseSessionId: number;
  students: User[];
  order: number;
};
export type CourseSession = {
  createdAt: string;
  updatedAt: string;
  students: User[];
  id: number;
  uuid: string;
  password: string;
  name: string;
  groupLayouts: CourseGroupLayout[];
  defaultGroupLayout: CourseGroupLayout;
  currentGroupLayout: CourseGroupLayout;
  currentGroupLayoutId: number;
  activityProgresses: ActivityProgress[];
  owners: User[];
  currentMode: "COURSE" | "TP" | "EXAM";
  visibility: string;
  team: Team;
  teamId: string;
  perms: AccessControlEntry[];
};
export type CreateCourseSessionDto = {
  name: string;
  teamId: string;
};
export type PaginatedResponseOfCourseSession = {
  total: number;
  limit: number;
  offset: number;
  results: CourseSession[];
};
export type UpdateCourseSessionDto = {
  name?: string;
};
export type CourseSessionRoleDto = {
  role: "PROF" | "STUDENT";
};
export type UpdateCourseSessionActivitiesDto = {
  activities: Activity[];
};
export type UpdateCurrentModeDto = {
  mode: "COURSE" | "TP" | "EXAM";
};
export type CurrentGroupStateDto = {
  activityProgressId?: number;
  slideId?: number;
  slideProgressId?: number;
};
export type ActivityStatsSummaryDto = {
  total: number;
  visited: number;
  answered: number;
  withData: number;
};
export type ActivityStatsDto = {
  summary: ActivityStatsSummaryDto;
};
export type SlideProgressSummary = {
  slideProgressId: number;
  slideId: number;
  groupId: number;
  visited: boolean;
  hasAnswers: boolean;
  hasData: boolean;
  quizType:
    | "NONE"
    | "FREEFORM"
    | "SINGLE_CHOICE"
    | "MULTIPLE_CHOICE"
    | "REORDER_CHOICE";
};
export type GroupProgressDto = {
  groupId: number;
  groupName: string;
  slideProgressRecords: SlideProgressSummary[];
};
export type GroupLayoutProgressForActivityDto = {
  groupLayoutId: number;
  groupLayoutName: string;
  groupProgress: GroupProgressDto[];
};
export type ActivityProgressByGroupLayoutsDto = {
  activityProgressId: number;
  activityId: number;
  activityName: string;
  groupLayoutProgress: GroupLayoutProgressForActivityDto[];
};
export type ActivityProgressForGroupDto = {
  slideProgressRecords: SlideProgressSummary[];
  activityProgressId: number;
  activityId: number;
};
export type CurrentGroupProgressDto = {
  activityProgressRecords: ActivityProgressForGroupDto[];
  groupId: number;
  courseSessionId: number;
};
export type GroupLayoutProgressDto = {
  groups: CurrentGroupProgressDto[];
  id: number;
  name: string;
};
export type CreateCourseGroupDto = {
  name?: string;
  users?: User[];
};
export type UpdateSlideProgressDto = {
  quizAnswers?: string[];
  visited?: boolean;
};
export type CreateActivityProgressDto = {
  activityId: number;
};
export type UpdateActivityProgressDto = {
  activityId?: number;
};
export type CreateSlideProgressDto = {};
export type PackageDto = {
  namespace: string;
  packageName: string;
};
export type PackageVersionSummaryDto = {
  namespace: string;
  packageName: string;
  version: string;
  revision: string;
};
export type ArtifactRelease = {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  channel: string;
  artifact: PackageVersionSummaryDto;
};
export type CreateArtifactReleaseDto = {
  name: string;
  channel: string;
};
export type PaginatedResponseOfArtifactRelease = {
  total: number;
  limit: number;
  offset: number;
  results: ArtifactRelease[];
};
export type UpdateArtifactReleaseDto = {
  name: string;
  channel: string;
  artifact?: PackageVersionSummaryDto;
};
export type TeamStorageDto = {
  total: number;
  layers: number;
  models: number;
};
export const {
  useGetBackendVersionQuery,
  useGetClientAppStatusMutation,
  useAuthControllerGetAuthMethodsQuery,
  useAuthControllerOldVersionLoginMutation,
  useAuthControllerLocalLoginMutation,
  useValidAcceptInviteLinkMutation,
  useValidPasswordResetLinkMutation,
  useResetPasswordMutation,
  useAcceptTeamInvitationMutation,
  useRecoverAccountMutation,
  useAuthControllerAuthStatusQuery,
  useAuthControllerGetAbilitiesQuery,
  useAuthControllerLogoutMutation,
  useAuthControllerRefreshTokenQuery,
  useSocialGoogleLoginQuery,
  useSocialGoogleLoginRedirectQuery,
  useSocialGoogleIdLoginQuery,
  useSocialGoogleIdLoginRedirectQuery,
  useSocialCasLoginQuery,
  useSocialCasLoginWithIdQuery,
  useCreateUserMutation,
  useFindAllUsersQuery,
  useUpdateUserMutation,
  useFindOneUserQuery,
  useRemoveUserMutation,
  useFindAllUsersPaginatedQuery,
  useGetUserAuthProvidersQuery,
  useRemoveAuthProviderMutation,
  useAddLocalPasswordMutation,
  useAddSocialAccountMutation,
  useCreateLocalUserMutation,
  useUpdateMineUserMutation,
  useFindMineUserQuery,
  useGetMyAuthProvidersQuery,
  useRemoveMyAuthProviderMutation,
  useAddMyLocalPasswordMutation,
  useAddMySocialAccountMutation,
  useTeamControllerCreateMutation,
  useTeamControllerFindAllQuery,
  useTeamControllerFindOneQuery,
  useTeamControllerUpdateMutation,
  useTeamControllerRemoveMutation,
  useMembershipControllerAddMembersMutation,
  useMembershipControllerGetMembersQuery,
  useMembershipControllerInviteMembersMutation,
  useMembershipControllerGetMemberQuery,
  useMembershipControllerRemoveMemberMutation,
  useMembershipControllerUpdateMemberMutation,
  useTeamRoleControllerCreateRoleMutation,
  useTeamRoleControllerGetTeamRolesQuery,
  useTeamRoleControllerUpdateRoleMutation,
  useMyTeamsControllerFindMineQuery,
  useMyTeamsControllerFindOneWithUserMembershipQuery,
  useTerrainsControllerCreateMutation,
  useTerrainsControllerFindAllPaginatedQuery,
  useTerrainsControllerUpdateMutation,
  useTerrainsControllerRemoveMutation,
  useTerrainsControllerFindOneQuery,
  useTerrainsControllerGetTerrainModelLinksQuery,
  useTerrainsControllerDeleteTerrainModelLinksMutation,
  useTerrainsControllerDeleteTerrainModelLinkMutation,
  useTerrainsControllerUpdateTerrainModelLinkMutation,
  useTerrainsControllerCreateTerrainModelLinkMutation,
  useTerrainsControllerGetTerrainLayersQuery,
  useTerrainsControllerCreateTerrainLayerMutation,
  useTerrainsControllerCreateTerrainLayerLinkMutation,
  useTerrainsControllerDetachLayerMutation,
  useTerrainsControllerAddUserMutation,
  useTerrainsControllerGetMembershipsQuery,
  useTerrainsControllerDeleteMembershipMutation,
  useTerrainsControllerChangeVisibilityMutation,
  useTerrainsControllerChangeTeamMutation,
  useLayerControllerCreateMutation,
  useLayerControllerFindAllQuery,
  useLayerControllerFindOneQuery,
  useLayerControllerUpdateMutation,
  useLayerControllerRemoveMutation,
  useLayerControllerRetrieveBoundsFromSourceQuery,
  useLayerControllerAddUserMutation,
  useLayerControllerGetMembershipsQuery,
  useLayerControllerDeleteMembershipMutation,
  useLayerControllerChangeVisibilityMutation,
  useLayerControllerChangeTeamMutation,
  useManagedLayerControllerGetAllQuery,
  useManagedLayerControllerGetOneQuery,
  useManagedLayerControllerUpdateMutation,
  useManagedLayerControllerDeleteMutation,
  useManagedLayerControllerUploadChunkMutation,
  useManagedLayerControllerDeleteSourceFileMutation,
  useManagedLayerControllerDownloadSourceFileQuery,
  useManagedLayerControllerClearSourceFilesUsedForMutation,
  useManagedLayerControllerClearTmsFilesMutation,
  useManagedLayerControllerBuildMutation,
  useManagedLayerSourcesControllerToucheSourceFilesMutation,
  useJobControllerCreateMutation,
  useJobControllerFindAllQuery,
  useJobControllerFindOneQuery,
  useJobControllerUpdateMutation,
  useJobControllerRemoveMutation,
  useStartJobMutation,
  useStopJobMutation,
  useJobLifeCycleControllerGetJobStatusQuery,
  useJobLifeCycleControllerGetJobHistoryQuery,
  useJobLifeCycleControllerCleanHistoryMutation,
  useJobLifeCycleControllerGetJobLogsQuery,
  useJobLifeCycleControllerClearLogsMutation,
  useAmqpJobControllerLolQuery,
  useDocumentMembershipControllerGetDocumentMembershipsQuery,
  useDocumentMembershipControllerRevokeDocumentMembershipMutation,
  useDocumentRoleControllerGetRolesQuery,
  useTerrainModelsControllerCreateMutation,
  useTerrainModelsControllerGetAllQuery,
  useTerrainModelsControllerRemoveMutation,
  useTerrainModelsControllerGetOneQuery,
  useTerrainModelsControllerUpdateMutation,
  useTerrainModelsControllerAddUserMutation,
  useTerrainModelsControllerGetMembershipsQuery,
  useTerrainModelsControllerDeleteMembershipMutation,
  useTerrainModelsControllerChangeVisibilityMutation,
  useTerrainModelsControllerChangeTeamMutation,
  useTerrainModelFileControllerCreateRevisionMutation,
  useTerrainModelFileControllerFindRevsQuery,
  useTerrainModelFileControllerCleanUpRevsMutation,
  useTerrainModelFileControllerUploadPartMutation,
  useTerrainModelFileControllerCompleteUploadMutation,
  useTerrainModelFileControllerFindByRevIdQuery,
  useTerrainModelFileControllerDeleteRevMutation,
  useTerrainModelFileControllerSetCurrentMutation,
  useTerrainModelsCapabilitiesControllerGetCapabilitiesQuery,
  useActivitiesControllerCreateMutation,
  useActivitiesControllerFindAllPaginatedQuery,
  useActivitiesControllerCreateSlideMutation,
  useActivitiesControllerGetSlidesQuery,
  useActivitiesControllerUpdateSlidesOrderMutation,
  useActivitiesControllerFindOneQuery,
  useActivitiesControllerUpdateMutation,
  useActivitiesControllerRemoveMutation,
  useActivitiesControllerGetSlidesSummaryQuery,
  useActivitiesControllerAddUserMutation,
  useActivitiesControllerGetMembershipsQuery,
  useActivitiesControllerDeleteMembershipMutation,
  useActivitiesControllerChangeVisibilityMutation,
  useActivitiesControllerChangeTeamMutation,
  useGetAllSlidesQuery,
  useGetSlideQuery,
  useUpdateSlideMutation,
  useDeleteSlideMutation,
  useSlidesControllerCopySlideMutation,
  useSlidesControllerLinkTerrainMutation,
  useUnlinkTerrainFromSlideMutation,
  useSlidesControllerAddGeofeatureMutation,
  useSlidesControllerClearGeofeatureMutation,
  useSlidesControllerDeleteGeofeatureMutation,
  useAttachFileToSlideMutation,
  useUploadFileToSlideMutation,
  useSlidesFilesControllerGetAllMediaQuery,
  useSlidesFilesControllerGetFileStreamQuery,
  useDeleteFileFromSlideMutation,
  useDetachFileFromSlideMutation,
  useSlidesFilesControllerUploadGeoJsonToSlideMutation,
  useDownloadedFilesControllerUploadMutation,
  useDownloadedFilesControllerFindAllQuery,
  useDownloadedFilesControllerFindOneQuery,
  useDownloadedFilesControllerRemoveMutation,
  useCourseSessionControllerCreateMutation,
  useCourseSessionControllerFindAllQuery,
  useCourseSessionControllerFindAllPaginatedQuery,
  useCourseSessionControllerFindOneQuery,
  useCourseSessionControllerUpdateMutation,
  useCourseSessionControllerRemoveMutation,
  useCourseSessionControllerGetMyRoleQuery,
  useCourseSessionControllerGetActivitiesQuery,
  useCourseSessionControllerCreateAndConnectedActivityMutation,
  useCourseSessionControllerUpdateActivitiesMutation,
  useCourseSessionControllerConnectActivityMutation,
  useCourseSessionControllerSetCurrentModeMutation,
  useCourseSessionControllerDisconnectActivityMutation,
  useCourseSessionControllerGetOneActivityProgressQuery,
  useCourseSessionControllerGetAllActivityProgressQuery,
  useCourseSessionControllerGetSlidesSummaryForActivityProgressQuery,
  useCourseSessionControllerGetAllGroupsForCourseSessionQuery,
  useCourseSessionControllerGetAllSlideProgressRecordsForActivityProgressQuery,
  useCourseSessionControllerGetCurrentGroupForUserQuery,
  useCourseSessionControllerGetCurrentStateForGroupQuery,
  useCourseSessionControllerSetCurrentStateForGroupMutation,
  useCourseSessionControllerGetAllSlideProgressRecordsForSlideQuery,
  useCourseSessionControllerGetSlideProgressForGroupQuery,
  useCourseSessionControllerAddFileToSlideProgressMutation,
  useCourseSessionControllerDetachFileFromSlideProgressMutation,
  useCourseSessionControllerUploadFileToSlideProgressMutation,
  useCourseSessionControllerGetFilesOfSlideProgressQuery,
  useCourseSessionControllerResetSessionMutation,
  useCourseSessionControllerSetOwnerMutation,
  useCourseSessionControllerAddUserMutation,
  useCourseSessionControllerGetMembershipsQuery,
  useCourseSessionControllerDeleteMembershipMutation,
  useCourseSessionControllerChangeVisibilityMutation,
  useCourseSessionControllerChangeTeamMutation,
  useCourseSessionStatsControllerGetActivityStatsQuery,
  useCourseSessionStatsControllerGetActivityProgressSummariesQuery,
  useCourseSessionStatsControllerGetCurrentGroupProgressQuery,
  useCourseSessionStatsControllerGetAllGroupLayoutProgressQuery,
  useCourseSessionStatsControllerGetOneGroupLayoutProgressQuery,
  useCourseSessionStatsControllerGetCurrentGroupLayoutProgressQuery,
  useCourseSessionStudentsControllerAddStudentMutation,
  useCourseSessionStudentsControllerRemoveStudentMutation,
  useCourseSessionStudentsControllerGetAllStudentsQuery,
  useCreateCourseGroupMutation,
  useGetAllCourseGroupsQuery,
  useGetCourseGroupQuery,
  useUpdateCourseGroupMutation,
  useDeleteCourseGroupMutation,
  useSlideProgressControllerFindOneQuery,
  useSlideProgressControllerUpdateMutation,
  useSlideProgressControllerAttachFileMutation,
  useSlideProgressControllerGetFileDataQuery,
  useSlideProgressControllerDetachFileMutation,
  useSlideProgressControllerUploadFileMutation,
  useSlideProgressControllerDeleteFileMutation,
  useSlideProgressControllerGetFilesQuery,
  useSlideProgressControllerCreateGeoFeatureMutation,
  useSlideProgressControllerGetAllGeoFeaturesQuery,
  useSlideProgressControllerGetAllSlideGeoFeaturesQuery,
  useSlideProgressControllerDeleteGeoFeatureMutation,
  useActivityProgressControllerCreateMutation,
  useGetAllActivityProgressQuery,
  useGetActivityProgressQuery,
  useUpdateActivityProgressMutation,
  useDeleteActivityProgressMutation,
  useActivityProgressControllerGetActivityProgressSlidesQuery,
  useActivityProgressControllerCreateSlideProgressMutation,
  useActivityProgressControllerGetSlideProgressQuery,
  useUpdateSlideProgressMutation,
  useActivityProgressControllerAddFileToSlideProgressMutation,
  useActivityProgressControllerRemoveFileFromSlideProgressMutation,
  useActivityProgressControllerUploadFileToSlideProgressMutation,
  usePostAnswerMutation,
  useUpdateAnswerMutation,
  useGetAnswerQuery,
  useArtifactsControllerGetPackagesQuery,
  useArtifactsControllerGetVersionsQuery,
  useArtifactsControllerCreateReleaseChannelMutation,
  useArtifactsControllerGetReleaseChannelsPaginatedQuery,
  useArtifactsControllerGetReleaseChannelQuery,
  useArtifactsControllerUpdateReleaseMutation,
  useArtifactsControllerDeleteReleaseMutation,
  useArtifactsControllerGetReleaseRssByNameAndChannelQuery,
  useArtifactsControllerGetArtifactRssQuery,
  useArtifactsControllerGetReleaseAssetFileQuery,
  useTeamStorageControllerGetStorageStatsQuery,
} = api;
